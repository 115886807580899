@if (showGraph) {
  <div>
    <div echarts [options]="options" [loading]="loader" theme="macarons" [merge]="updateOptions"
         [ngClass]="{'small-e-chart':isSmallEChart}" 
         [ngStyle]="{height: forceChartHeightInPx ? forceChartHeightInPx : ''}" (chartClick)="onChartClick($event)">
    </div>
    @if (showDataUnavailabe && !loader) {
      <div
        style="text-align: center; width: 100%; height: auto; position: absolute; left: 0; top: 100px; z-index: 499;">
        <h4 class="asset-not-found"><span>&ldquo;</span>{{ 'sys.no-data-available' | translate }}<span>&rdquo;</span>
        </h4>
      </div>
    }
  </div>
}
