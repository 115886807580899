import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../../services/config.service';
import {DateFormatterService} from '../../../services/dateformat';
import {cloneDeep, findIndex, forEach, keys, map, orderBy, union} from 'lodash';
import {NamingConventionFilterPipe} from '../../../pipes/namingConvention.pipe';
import {OrderStatusColorValuesService} from '../../../services/order-status-color-values.service';
import moment from 'moment-timezone';
import {LangUtilService} from '../../util/lang-util.service';

import {CONSTANT} from '../../../config/constant';
import {Observable} from 'rxjs';

const MMD_MODULE_NAME = CONSTANT.DMS_MODULES.MMD.MODULE;
const LMD_MODULE_NAME = CONSTANT.DMS_MODULES.LMD.MODULE;
const MOBILE_BREAK_POINT = 481


@Injectable({
  providedIn: 'root'
})

export class CommonService {

  agentsDetails: any = [];

  constructor(private http: HttpClient, public configService: ConfigService, private orderStatusColorService: OrderStatusColorValuesService,
              public dateFormatter: DateFormatterService, private lang: LangUtilService) {
  }

  buildRowExpansionData(deliveries, allowedStatusesObj?, moduleName?, newDeliveryIds?) {
    const rowExpansionData = [];
    forEach(deliveries, (deliveryData) => {
      const obj = {
        'orderId': null,
        'deliverySequence': 'R',
        'statusDate': null,
        'status': null,
        'deliveryId': null,
        'deliveryLocation': {
          lat: null,
          lng: null
        },
        tempDetails: deliveryData?.completed_temp ? `${deliveryData?.completed_temp?.toFixed(2)} °C`: '-',
      };

      let roundTripOrderLabel = '';
      if (deliveryData.isRoundTripCompleted) {
        roundTripOrderLabel = CONSTANT.DELIVERY_STATUS.LABELS.RETURNED;
        // obj.status = CONSTANT.DELIVERY_STATUS.LABELS.RETURNED;
      } else if (deliveryData && deliveryData.status) {
        if (deliveryData.status === CONSTANT.DELIVERY_STATUS.PENDING) {
          roundTripOrderLabel = CONSTANT.DELIVERY_STATUS.LABELS.ROUNDTRIP;
        } else if (deliveryData.status === CONSTANT.DELIVERY_STATUS.RETURNING) {
          roundTripOrderLabel = CONSTANT.DELIVERY_STATUS.LABELS.RETURNING_TO;
        } else if (deliveryData.status === CONSTANT.DELIVERY_STATUS.COMPLETED) {
          roundTripOrderLabel = CONSTANT.DELIVERY_STATUS.LABELS.RETURNED;
          //  obj.status = CONSTANT.DELIVERY_STATUS.LABELS.RETURNED;
        }
      }

      const deliveredOrders = deliveryData.deliveryOrderStatus ? [...deliveryData.deliveryOrderStatus] : [];
      const cancelledOrders = deliveryData.cancelledDeliveries ? deliveryData.cancelledDeliveries : [];
      let roundTripFlag = false;
      deliveryData = {...deliveryData, batchSize: deliveredOrders?.length + cancelledOrders?.length}

      if (deliveryData.isRoundTrip) {
        obj.orderId = roundTripOrderLabel;
        obj.deliveryId = deliveryData._id;
        if (deliveryData.endLocation && deliveryData.endLocation.lat && deliveryData.endLocation.lng) {
          obj.deliveryLocation['lat'] = deliveryData.endLocation.lat;
          obj.deliveryLocation['lng'] = deliveryData.endLocation.lng;
        }
        const r = deliveryData.deliveryOrderStatus.find((o: any) => {
          return o.deliverySequence == 'R';
        });
        if (!r && deliveryData.deliveryOrderStatus) {
          roundTripFlag = true;
        }
      }
      if(roundTripFlag){
        deliveredOrders.push(obj)
       }

      let orders = union(deliveredOrders, cancelledOrders);
      orders = orderBy(orders, ['deliverySequence', 'orderId'], ['asc']);
      const finalData = {
        ...deliveryData,
        deliveryOrderStatus: roundTripFlag? [...deliveryData.deliveryOrderStatus, obj]: deliveryData.deliveryOrderStatus,
        deliveredAndCancelledOrders: cloneDeep(orders)
      };

      const isNewDelivery = newDeliveryIds?.find(id => id === deliveryData?._id)

      if (orders && orders.length > 0) {
        forEach(orders, (order) => {
          if(isNewDelivery){
            order = {...order, isNewDelivery: true}
          }
          rowExpansionData.push(this.constructOrder(order, finalData, allowedStatusesObj, moduleName));
        });
      }
    });
    return rowExpansionData;
  }

  constructOrder(order, deliveryData, allowedStatusesObj, moduleName) {
    const indOStatus = order?.status;
    const orderDetails = cloneDeep(order);
    orderDetails.deliveryData = deliveryData;
    orderDetails['deliveryId'] = deliveryData._id;
    orderDetails['created'] = deliveryData?.created;
    orderDetails['batchSize'] = deliveryData?.batchSize;
    orderDetails['delvieryGrpSequence'] = deliveryData?.delvieryGrpSequence;
    orderDetails['creationType'] = deliveryData?.creationType;



    orderDetails['customerTrack'] = true;
    orderDetails['removeOrderAssignment'] = true;

    if (order.orderId && (order.orderId === CONSTANT.DELIVERY_STATUS.LABELS.ROUNDTRIP || order.orderId && order.orderId === CONSTANT.DELIVERY_STATUS.LABELS.RETURNING_TO)) {
      orderDetails['customerTrack'] = false;
      orderDetails['removeOrderAssignment'] = false;
    }

    if(indOStatus && order?.temperature?.[indOStatus]?.value){
      orderDetails['tempDetails'] = order.temperature?.[indOStatus]?.value == 'NA' ? order.temperature?.[indOStatus]?.value : order.temperature?.[indOStatus]?.value?.toFixed(2) + ' °C';
      if(order.temperature[indOStatus]?.date ) {
       orderDetails['tempDetailsTooltip'] = (order.temperature?.[indOStatus]?.label ?? 'Status') + ' Temperature : ' + this.dateFormatterToText(order.temperature?.[indOStatus]?.date);
      }
    }
    if (order.customer && order.customer.name) {
      if (moduleName && moduleName === LMD_MODULE_NAME) {
        orderDetails['customer'] = order.customer.name;
      }
      if (moduleName && moduleName === MMD_MODULE_NAME) {
        orderDetails['deliveryCenter'] = order.customer.name;
      }
    }

    if (order.orderId) {
      orderDetails['orderId'] = order.orderId;
      if (order?.orderId === CONSTANT.DELIVERY_STATUS.LABELS.RETURNED) {
        if(deliveryData?.returnTripTriggerType) {
          order.status = deliveryData.returnTripTriggerType;
        }
      }
    }
    if (deliveryData.pickUpLocation && deliveryData.pickUpLocation.name) {
      orderDetails['pickupLocation'] = deliveryData.pickUpLocation.name;
      /* if (moduleName && moduleName === LMD_MODULE_NAME) {
        orderDetails['pickupLocation'] = deliveryData.pickUpLocation.name;
      }
      if (moduleName && moduleName === MMD_MODULE_NAME) {
        orderDetails['processingCenter'] = deliveryData.pickUpLocation.name;
      } */
    }
    if (deliveryData.pickUpCity && deliveryData.pickUpCity.city) {
      orderDetails['pickupCity'] = deliveryData.pickUpCity.city;
    }
    if (deliveryData.route) {
      orderDetails['route'] = deliveryData.route;
    }
    if (deliveryData.agentId) {
      if (moduleName && moduleName === LMD_MODULE_NAME) {
        orderDetails['avtarId'] = deliveryData.agentId;
      }
      orderDetails['agentId'] = deliveryData.agentId;
    }
    if (deliveryData.deliveredAndCancelledOrders) {
      orderDetails['deliveredAndCancelledOrders'] = deliveryData.deliveredAndCancelledOrders;
    }
    if (order.expectedDel && order.expectedDel.date) {
      const date = order.expectedDel.date;
      const slot = order.expectedDel.slot ? order.expectedDel.slot : null;
      orderDetails['expectedDeliveryDateTimeDetails'] = this.buildExpectedDeliveryDate(date, slot);
    }
    if (order.statusDate) {
      //  orderDetails['statusDateAndTime'] = this.getDateAndSingleTime(order.statusDate, order.statusDate);
      orderDetails['statusDateAndTime'] = this.getDateAndTime(order.statusDate, order.statusDate);
    } else if (deliveryData.roundTripCompleteDate) {
      //  orderDetails['statusDateAndTime'] = this.getDateAndSingleTime(deliveryData.roundTripCompleteDate, deliveryData.roundTripCompleteDate);
      orderDetails['statusDateAndTime'] = this.getDateAndTime(deliveryData.roundTripCompleteDate, deliveryData.roundTripCompleteDate);
    }
    if (order.status) {
      if (allowedStatusesObj) {
        const foundObject = allowedStatusesObj?.[order.status]
        orderDetails['orderStatus'] = {
          orderStatus: order.status,
          options: foundObject ?? [],
          statusDate: orderDetails['statusDateAndTime'] ? orderDetails['statusDateAndTime'] : '',
        };
      } else {
        orderDetails['orderStatus'] = order.status;
      }
    }

    if(deliveryData?.delvieryGrpSequence) {
      orderDetails['rowHeader'] = deliveryData.delvieryGrpSequence ?? '-';
    }

    /* if (deliveryData.carrier && deliveryData.carrier.length > 0) {
     let rowSubHeader: string;
     rowSubHeader = '';
     if(deliveryData?.carrier?.[0]?.name) {
      rowSubHeader = rowSubHeader + deliveryData.carrier[0].name
     }
     if (deliveryData?.carrier?.[0]?.mobileNo) {
      rowSubHeader = rowSubHeader + ' (' + deliveryData.carrier[0].mobileNo + ') '
     }
     orderDetails['rowSubHeader'] = rowSubHeader
     orderDetails['mobileNo'] = deliveryData.carrier[0].mobileNo ? deliveryData.carrier[0].mobileNo : '-';
    } */

    if(deliveryData?.carrierDetails){
      let rowSubHeader: string;
       rowSubHeader = '';

       if(deliveryData?.carrierDetails?.name){
        orderDetails['carrierName'] = deliveryData?.carrierDetails?.name
        rowSubHeader = rowSubHeader + deliveryData?.carrierDetails?.name
       }
       if (deliveryData?.carrierDetails?.mobileNo) {
        rowSubHeader = rowSubHeader + ' (' + deliveryData.carrierDetails?.mobileNo + ') '
       }
       orderDetails['rowSubHeader'] = rowSubHeader
       orderDetails['mobileNo'] = deliveryData?.carrierDetails?.mobileNo ?? '-';
    }

    if (deliveryData.routePlanNames) {
      orderDetails['routePlanNames'] = deliveryData.routePlanNames;
    }
    if(deliveryData?.routePlanCategories){
      orderDetails['routePlanCategories'] = deliveryData.routePlanCategories;
    }

    orderDetails['horizontalTimeline'] = this.setHorizontalTimelineData(deliveryData['deliveredAndCancelledOrders'], deliveryData['route'], moduleName, deliveryData);
    orderDetails['horizontalTimelineLeftGridContent'] = this.setHorizontalTimelineLeftGridContent(deliveryData);
    orderDetails['horizontalTimelineRightGridContent'] = this.setHorizontalTimelineRightGridContent(deliveryData);
    if(deliveryData?.status){
      orderDetails['deliveryGroupStatus'] = deliveryData?.status
    }
    
    return orderDetails;
  }

  setHorizontalTimelineData(orders, route, moduleName, deliveryData) {
    let oLabel = 'Order';
    if (moduleName && moduleName === MMD_MODULE_NAME) {
      oLabel = 'Consignment';
    }

    const timelineArray: any = [
      {
        valueInCircle: 'P',
      }
    ];

    if (deliveryData.statusTimeLine && deliveryData.statusTimeLine.pickedUp) {
      this.sortDate(deliveryData.statusTimeLine.pickedUp);
      const startDate = this.sortDate(deliveryData.statusTimeLine.pickedUp);



      let date = this.dateFormatter.convertDateToSelectedTimezone(startDate, 'DD/MM/YY');
          date += ', ' + this.dateFormatter.getFormattedTimeSlotForSingleTime(startDate);

      const pickUpDate = { label: 'Pickup Date', value: date || '', fieldId: 'pickupDate' }
      const pickUpLocation = { label: 'Pickup Location', value: deliveryData?.pickUpLocation?.name || '', fieldId: 'pickupLocation' }
      const delvieryGrpSequence = { label: 'Id', value: deliveryData?.delvieryGrpSequence || '', fieldId: 'delvieryGrpSequence' }

      const orderDetails = {
        delvieryGrpSequence: delvieryGrpSequence,
        pickupLocation: pickUpLocation,
        pickupDate: pickUpDate,
        consignmentNo: {
          label: '',
          value: oLabel + ' Pickup',
        }
      }
      timelineArray[0]['orderDetails'] = { ...orderDetails };
    }

    orders = orderBy(orders, ['deliverySequence'], ['asc']);

    let routeWaypointsIndex = 0;
    let startPoint
    forEach(orders, (value, index: any) => {
      const timelineObj = {};

      const deliveryLocationLat = value && value.deliveryLocation && value.deliveryLocation.lat;
      const deliveryLocationLng = value && value.deliveryLocation && value.deliveryLocation.lng;
      const orderStatus = value && value.status;
      let dataNotMissing = false;
      if (deliveryLocationLat && deliveryLocationLng && this.checkIfOrderNotCancelled(orderStatus)) {
        dataNotMissing = true;
      }

      timelineObj['orderDetails'] = this.createHorizontalTimelineOrderDetailsObj(value, moduleName, deliveryData);
      timelineObj['valueInCircle'] = value.deliverySequence;
      if (value?.orderId === CONSTANT.DELIVERY_STATUS.LABELS.RETURNED) {
        if (deliveryData?.isAutoReturned) {
          timelineObj['status'] = 'autoReturned';
        } else if (deliveryData?.isManuallyReturned) {
          timelineObj['status'] = 'manuallyReturned';
        }
      } else if (value?.orderId === CONSTANT.DELIVERY_STATUS.LABELS.RETURNING_TO) {
        timelineObj['status'] = 'returningTo';
      } else {
        timelineObj['status'] = value.status;
      }
      timelineObj['nonComplianceTempEvents'] = value?.nonCompliance?.temperature?.events
      timelineObj['nonComplianceTempTotalEvents'] = value?.nonCompliance?.temperature?.totalCount
      if (value.status) {
        const color = this.orderStatusColorService.getStatusColor(value.status);
        timelineObj['circleActiveTextColor'] = color;
        switch (value.status) {
          case 'pickedUp':
           // timelineArray[0]['circleActiveTextColor'] = '#23afbd';
            break;
          case 'delivered':
            timelineArray[0]['circleActiveTextColor'] = '#22C55E';
            break;
          default:
            break;
        }
      } else if (value?.orderId === CONSTANT.DELIVERY_STATUS.LABELS.RETURNED) {
        if(deliveryData?.isAutoReturned) {
          const color = this.orderStatusColorService.getStatusColor('AutoReturned');
          timelineObj['circleActiveTextColor'] = color;
        } else if(deliveryData?.isManuallyReturned) {
          const color = this.orderStatusColorService.getStatusColor('ManuallyReturned');
          timelineObj['circleActiveTextColor'] = color;
        }
      }
      timelineArray.push(timelineObj);

      if (value.status) {
        if (value.status === 'delivered') {
          timelineArray[index]['lineActiveColor'] = 'green';
        } else if (value.status === 'deliveryOnHold') {
          timelineArray[index]['lineActiveColor'] = '#ce4a4a';
        } else if (!this.checkIfOrderNotCancelled(value.status)) {
          // timelineArray[index]['lineActiveColor'] = '#c63737';
        }
      } else if (value?.orderId === CONSTANT.DELIVERY_STATUS.LABELS.RETURNED && deliveryData?.isAutoReturned) {
        timelineArray[index]['lineActiveColor'] = 'green';
      }

      let routeWaypoints = [];
      routeWaypoints = route && route.wayPointDetails;

      if (dataNotMissing) {
        if (routeWaypoints && routeWaypoints[routeWaypointsIndex] && timelineArray[index]) {
          timelineArray[index]['textBtwCircles'] = routeWaypoints[routeWaypointsIndex].distance && routeWaypoints[routeWaypointsIndex].distance.text ? routeWaypoints[routeWaypointsIndex].distance.text : '';
          timelineArray[index]['valueBtwCircles'] = routeWaypoints[routeWaypointsIndex].distance && (routeWaypoints[routeWaypointsIndex].distance.value || routeWaypoints[routeWaypointsIndex].distance.value === 0) ? routeWaypoints[routeWaypointsIndex].distance.value : '';

          const endPoint= timelineArray[index]?.['valueInCircle']==='P' ? 1 :
            timelineArray[index]?.['valueInCircle'] === orders.length - 1 ? 'R' :
                     (1 + timelineArray[index]?.['valueInCircle'])

          timelineArray[index]['distanceTooltip']=
            timelineArray[index]['textBtwCircles'] + ' from ' + (startPoint || 'P') + ' to ' + (value?.deliverySequence || '')
          startPoint=endPoint

        }
        routeWaypointsIndex = routeWaypointsIndex + 1;
      } else {
        timelineArray[index]['distanceTooltip']='Not Available'
        timelineArray[index] ? timelineArray[index]['textBtwCircles'] = 'NA' : '';
        timelineArray[index + 1] ? timelineArray[index + 1]['dataMissing'] = true : '';
      }

      /* forEach(routeWaypoints, function(value, i) {
       if(timelineArray[i]) {
         timelineArray[i]['textBtwCircles'] = value.distance && value.distance.text ? value.distance.text : '';
         timelineArray[i]['valueBtwCircles'] = value.distance && (value.distance.value || value.distance.value === 0) ? value.distance.value : '';
       }
      }); */

    });

    // console.log('timelineArray', timelineArray);
    const cIndex = timelineArray.findIndex((type) => type.valueInCircle === "R");
    if (cIndex > -1) {
      timelineArray[cIndex]['nonComplianceTempEvents'] = deliveryData?.returning_nonCompliance?.temperature?.events
      timelineArray[cIndex]['nonComplianceTempTotalEvents'] = deliveryData?.returning_nonCompliance?.temperature?.totalCount
    }
    return timelineArray;
  }

  checkIfOrderNotCancelled(orderStatus) {
    let check = true;
    if (orderStatus) {
      if ((orderStatus === 'deliveryPartnerReturnedOrder') || (orderStatus === 'cancelled') || (orderStatus === 'lpCancelled')) {
        check = false;
      }
    }
    return check;
  }

  setHorizontalTimelineLeftGridContent(deliveryData) {
    const timelineArray: any = [];

    if (deliveryData.statusTimeLine && deliveryData.statusTimeLine.pickedUp) {
      this.sortDate(deliveryData.statusTimeLine.pickedUp);
      const startDate = this.sortDate(deliveryData.statusTimeLine.pickedUp);

      const isToday: boolean = this.dateFormatter.checkIfDateIsToday(startDate);

      const obj = {};
      const date = this.dateFormatter.convertDateToSelectedTimezone(startDate, 'DD/MM/YY');

      if (isToday) {
        obj['value'] = this.dateFormatter.getFormattedTimeSlotForSingleTime(startDate);
      } else {
        obj['value'] = date + ', ' + this.dateFormatter.getFormattedTimeSlotForSingleTime(startDate);
      }

      obj['placeholder'] = 'Pickup Date: ' + this.dateFormatterToText(startDate);
      timelineArray.push(obj);
    }

    // console.log('timelineArray', timelineArray);
    return timelineArray;
  }

  dateFormatterToText(date) {
    this.lang.updateMomentLanguage(moment);
    return moment(date).calendar();
  }

  sortDate(dates) {
    const pickedUpTime = dates;
    const pDate = map(keys(pickedUpTime), d => moment(pickedUpTime[d]));
    const startDate = moment.min(pDate);
    return startDate;
  }

  setHorizontalTimelineRightGridContent(deliveryData) {
    const timelineArray: any = [];

    if (deliveryData?.distance?.actual?.valueInKm) {
      const obj = {};
      obj['placeholder'] = 'Actual Distance';
      obj['value'] = parseFloat(deliveryData?.distance?.actual?.valueInKm).toFixed(2) + ' Km';
      timelineArray.push(obj);
    }

    if (deliveryData?.distance?.mapProvider?.text) {
      const obj = {};
      obj['placeholder'] = 'Expected Distance';
      obj['value'] = deliveryData?.distance?.mapProvider?.text;
      timelineArray.push(obj);
    }

    return timelineArray;
  }

  createHorizontalTimelineOrderDetailsObj(order, moduleName, delvieryData) {
    let oLabel = 'Order';
    if (moduleName && moduleName === MMD_MODULE_NAME) {
      oLabel = 'Consignment';
    }

    const orderDetails = {};

    const consignmentLabel = (order.orderId === CONSTANT.DELIVERY_STATUS.LABELS.RETURNED || order.orderId === CONSTANT.DELIVERY_STATUS.LABELS.ROUNDTRIP) ? CONSTANT.DELIVERY_STATUS.LABELS.ROUNDTRIP : (order.orderId === CONSTANT.DELIVERY_STATUS.LABELS.RETURNING_TO) ? order.orderId + ' ' + (delvieryData?.pickUpLocation?.name || delvieryData?.pickUpLocation?.branchCode || '') : (oLabel + ' ' + order.deliverySequence);
    const consignmentLabelInfo = { label: '', value: consignmentLabel, fieldId: 'consignmentNo' };



    const deliveredDate = delvieryData?.roundTripCompleteDate || ""
    let formattedDate: string
    if (deliveredDate) {
      formattedDate = this.dateFormatter.convertDateToSelectedTimezone(deliveredDate, 'DD/MM/YY');
      formattedDate += ', ' + this.dateFormatter.getFormattedTimeSlotForSingleTime(deliveredDate);
    }
    const deliveredDateInfo = {
      label: "Returned Date",
      value: formattedDate,
      fieldId: 'returnedDate'
    };

    const delvieryGrpSequence = (order.orderId === CONSTANT.DELIVERY_STATUS.LABELS.RETURNED || order.orderId === CONSTANT.DELIVERY_STATUS.LABELS.ROUNDTRIP || order.orderId === CONSTANT.DELIVERY_STATUS.LABELS.RETURNING_TO) ? delvieryData?.delvieryGrpSequence : order.orderId
    const delvieryGrpSequenceInfo = {
      label: 'Id',
      value: delvieryGrpSequence,
      fieldId: 'delvieryGrpSequence'
    }


    let returnedLabel = '';
    if (order.orderId === CONSTANT.DELIVERY_STATUS.LABELS.RETURNED) {
      if (delvieryData?.isAutoReturned) {
        returnedLabel = 'Auto Returned';
      } else if (delvieryData?.isManuallyReturned) {
        returnedLabel = 'Manually Returned';
      }
    }

    const statusInfo =
    {
      "label": "Status",
      "value": returnedLabel || order?.orderId || '',
      "id": returnedLabel?.trim()?.split(" ").join('') || order?.orderId?.trim()?.split(" ").join('') || '',
      "field": 'orderStatus',
      "fieldId": 'orderStatus'
    }

    const returningLocationInfo =
    {
      label: "Return Location",
      value: delvieryData?.pickUpLocation?.name || delvieryData?.pickUpLocation?.branchCode || '',
      "fieldId": 'returnLocation'
    }

    orderDetails['consignmentNo'] = consignmentLabelInfo;
    if (order?.orderId)
      orderDetails['delvieryGrpSequence'] = delvieryGrpSequenceInfo;

    if (!+(order.orderId)) {
      orderDetails['orderStatus'] = statusInfo;
      orderDetails['returnLocation'] = returningLocationInfo;
    }
    if (order?.orderId == CONSTANT.DELIVERY_STATUS.LABELS.RETURNED && formattedDate)
      orderDetails['returnedDate'] = deliveredDateInfo;

    if (order.customer && order.customer.name) {
      const obj = {};
      obj['label'] = 'Cust. Name';
      if (moduleName && moduleName === MMD_MODULE_NAME) {
        obj['label'] = 'Delivery Center';
      }
      obj['value'] = order.customer.name;
      obj['fieldId'] = 'deliveryCenter';
      orderDetails['deliveryCenter'] = obj;
    }
    if (order.expectedDel && order.expectedDel.date) {
      const date = order.expectedDel.date;
      const slot = order.expectedDel.slot ? order.expectedDel.slot : null;
      const obj = {};
      obj['label'] = 'Exp. Date';
      obj['value'] = this.buildExpectedDeliveryDate(date, slot);
      obj['fieldId'] = 'expDate';
      orderDetails['expDate'] = obj;
    }
    if (order.status) {
      const filterPipe = new NamingConventionFilterPipe();
      const fiteredString = filterPipe.transform(order.status);
      const obj = {};
      obj['label'] = 'Status';
      obj['value'] = fiteredString;
      obj['id'] = order.status;
      obj['field'] = 'orderStatus';
      obj['fieldId'] = 'orderStatus';
      orderDetails['orderStatus'] = obj;
    }
    if (order.statusDate) {
      const obj = {};
      obj['label'] = 'Status Date';
      const date = this.dateFormatter.convertDateToSelectedTimezone(order.statusDate, 'DD/MM/YY');
      obj['value'] = date + ', ' + this.dateFormatter.getFormattedTimeSlotForSingleTime(order.statusDate);
      obj['fieldId'] = 'statusDate';
      orderDetails['statusDate'] = obj;
    }
    return orderDetails;

  }

  buildExpectedDeliveryDate(date, time) {
    let dateString = '';
    let dateConcatinated = false;
    //If date eixsts and its todays date then dont display date
    if (date) {
      const now = new Date().toLocaleDateString();
      const currDate = new Date(date).toLocaleDateString();
      if (now !== currDate) {
        dateString = this.dateFormatter.convertDateToSelectedTimezone(date, 'DD/MM/YY');
        dateString = dateString?.substring(0, dateString.length - 3);
        dateConcatinated = true;
      }
    }
    if (time) {
      if (dateConcatinated) {
        dateString = dateString + ', ' + this.dateFormatter.getFormattedTimeSlot(time);
      } else {
        dateString = dateString + this.dateFormatter.getFormattedTimeSlot(time);
      }
    }
    return dateString;
  }

  setChangedOrderStatusInDeliveryGroup(deliveryGroups, order) {
    const deliveryGroupIndex: number = deliveryGroups.findIndex(del => order?._id == del?._id)

    if (deliveryGroupIndex !== -1) {
      deliveryGroups[deliveryGroupIndex] = order
    }

    return deliveryGroups;
  }

  getOrderMarkerData(deliveryData: any, moduleName?) {
    let oLabel = 'Order';
    let cLabel = 'Cust. Name';
    if (moduleName === MMD_MODULE_NAME) {
      oLabel = 'Delivery';
      cLabel = 'Delivery Center';
    }
    const pL = deliveryData?.pickUpLocation;
    const delO = deliveryData?.deliveredAndCancelledOrders;
    const markers = [];

    const namingConventionFilterPipe = new NamingConventionFilterPipe();
    if (pL) {
      const pickUpMarker = {
        lat: pL?.lat,
        lng: pL?.lng,
        name: 'PickUp Location',
        details: getPopUpHeader('Pickup Location : ', pL?.name || '') + showPopupData([]),
        shortHand: 'P',
        mOptions: {
          color: '#373fa3',
          fillColor: '#fff',
          fillOpacity: 0.8,
          radius: 15,
          weight: 3
        },
        pickupLocationId: pL._id,
        pickupLocationName: pL.name
      };
      markers.push(pickUpMarker);
    }

    forEach(delO, d => {
      if (d.status) {
        const fillColor = this.orderStatusColorService.getStatusBackgroundColor(d.status);
        const textColor = this.orderStatusColorService.getStatusColor(d.status);

        const toolTipData = namingConventionFilterPipe.transform(d.status);

        const expectedDelData =d?.expectedDel?.date ? this.dateFormatter.convertDateToSelectedTimezone(d.expectedDel.date, 'DD/MM/YY') : '';
        const expectedDelTime =d?.expectedDel?.date ? this.dateFormatter.getFormattedTimeSlot(d.expectedDel.slot) : '';

        const expDate=(expectedDelData || expectedDelTime) ? (expectedDelData +' - '+ expectedDelTime) : null

        const dLoc = d.deliveryLocation;
        const oId = d.orderId;

        const date=this.dateFormatter.convertDateToSelectedTimezone(d.statusDate, 'DD/MM/YY');
        const time = this.dateFormatter.getFormattedTimeSlotForSingleTime(d.statusDate);
        const sDate = (date || time) ? (date+', '+time) : null

        const dSeq = d.deliverySequence;
        // FIXME: dynamically get color base on delivery status

        const popupData=[]

        d?.orderId        && popupData.push({label:(oLabel+ ' ID : '),value:d?.orderId})
        d?.customer?.name && popupData.push({label:(cLabel+ ' : ' ),value:d?.customer?.name})
        expDate           && popupData.push({label:('Exp. Date : '),value:expDate})
        sDate             && popupData.push({label:'Status Date : ',value:sDate})
        toolTipData       && popupData.push({label:'Status : ',value:toolTipData,color:textColor})

        const m = {
          lat: dLoc && dLoc.lat,
          lng: dLoc && dLoc.lng,
          name: oId,
          details: `${getPopUpHeader(oLabel,dSeq)}${showPopupData(popupData)}`,

          date: d.statusDate, dSeq,
          shortHand: `${dSeq}`,
          mOptions: {
            color: textColor,
            fillColor: fillColor,
            fillOpacity: 0.8,
            radius: 15,
            weight: 3
          },
          iconConfig: {
            html: `<div> <span class='leaflet-circle-marker-txt' style='color: ${textColor}'>${dSeq} </span> </div>`,
            className: '',
            iconSize: [40, 40]
          },
          deliveryCenterId:d.deliveryCenterId,
          deliveryCenterName:d?.customer?.name
        };
        markers.push(m);
      }

    });

    return markers;
  }

  getActualMarkerData(rowData, locationArra, deliveryData, moduleName?) {
    let oLabel = 'Order';
    let cLabel = 'Cust. Name';
    if (moduleName === MMD_MODULE_NAME) {
      oLabel = 'Consignment';
      cLabel = 'Delivery Center';
    }

    const namingConventionFilterPipe = new NamingConventionFilterPipe();

    const delO = rowData.deliveredAndCancelledOrders;
    const orderId = deliveryData.assetObj && deliveryData.assetObj.order.orderId;
    const index = findIndex(delO, function (o: any) {
      return o.orderId == orderId;
    });
    let orderTooltip: any;
    let textColor;
    let toolTipData;
    let fillColor;
    let dSeq;
    if (index > -1) {
      const d = delO[index];
      textColor = this.orderStatusColorService.getStatusColor(d.status);
      toolTipData = namingConventionFilterPipe.transform(d.status);
      fillColor = this.orderStatusColorService.getStatusBackgroundColor(d.status);
      const expectedDelData = this.dateFormatter.convertDateToSelectedTimezone(d.expectedDel.date, 'DD/MM/YY');
      const expectedDelTime = this.dateFormatter.getFormattedTimeSlot(d.expectedDel.slot);
      const oId = d.orderId;
      dSeq = d.deliverySequence;
      orderTooltip = `<div style='padding: 15px'>
          <div>
          <b>${oLabel} ${dSeq}</b>
          </div>
          <div>
          <b>${oLabel} ID: </b>
          <span>${oId}</span>
          </br>
          <b>${cLabel}: </b>
          <span>${d?.customer?.name}</span>
          </br>
          <b>Exp. Date: </b>
          <span> ${expectedDelData} - ${expectedDelTime}</span>
          </br>
          <b>Status: </b>
          <span style='border: 1px solid ${textColor}; color: ${textColor}; border-radius: 5px; padding: 3px; font-weight: bold'>${toolTipData}</span>
          </div>
      </div>`;
    }

    const markers = [];
    let pickupLocation;
    let deliveryLocation;


    if (locationArra.length > 0) {
      pickupLocation = locationArra[0];
      deliveryLocation = locationArra[locationArra.length - 1];
      const pickUpMarker = {
        lat: pickupLocation[0],
        lng: pickupLocation[1],
        name: 'PickUp Location',
        details: `Pickup Location`,
        shortHand: 'P',
        mOptions: {
          color: '#373fa3',
          fillColor: '#fff',
          fillOpacity: 0.8,
          radius: 15,
          weight: 3
        }
      };
      markers.push(pickUpMarker);

      // let toolTipData = namingConventionFilterPipe.transform('delivered');
      const deliveryMarker = {
        lat: deliveryLocation[0],
        lng: deliveryLocation[1],
        name: 'Delivery Location',
        //details:` Delivery Location`,
        details: orderTooltip,
        shortHand: dSeq,
        mOptions: {
          color: textColor,
          fillColor: fillColor,
          fillOpacity: 0.8,
          radius: 15,
          weight: 3
        }
      };
      markers.push(deliveryMarker);

    }
    return markers;
  }

  getDateAndSingleTime(date, time) {
    const now = new Date().toLocaleDateString();
    const currStatusDate = new Date(date).toLocaleDateString();
    let dateStr = '';
    let dateConcatinated = false;

    //If the date is todays date then dont display date
    if (now !== currStatusDate) {
      dateStr = dateStr + this.dateFormatter.convertDateToSelectedTimezone(date, 'DD/MM/YY');
      dateStr = dateStr.substring(0, dateStr.length - 3);
      dateConcatinated = true;
    }
    if (dateConcatinated) {
      dateStr = dateStr + ', ' + this.dateFormatter.getFormattedTimeSlotForSingleTime(time);
    } else {
      dateStr = dateStr + this.dateFormatter.getFormattedTimeSlotForSingleTime(time);
    }

    return dateStr;
  }

  getDateAndTime(date, time) {
    let dateStr = '';
    dateStr = dateStr + this.dateFormatter.convertDateToSelectedTimezone(date, 'DD/MM/YY');
    dateStr = dateStr.substring(0, dateStr.length - 3);
    dateStr = dateStr + ', ' + this.dateFormatter.getFormattedTimeSlotForSingleTime(time);
    return dateStr;
  }

  getRoundTripSettingsByModuleType(moduleType: string): Observable<IRoutePlanSettings> {

    let url = this.configService.appConfig.appBaseUrl + 'deliveryManagement/settings/roundTrip/moduleType/';
    if (moduleType) {
      url = url + moduleType;
    }
    return this.http.get<IRoutePlanSettings>(url);
  }

  setRoundTripSettings(moduleType: string, payload: IRoutePlanSettings) {
    let url = this.configService.appConfig.appBaseUrl + 'deliveryManagement/settings/roundTrip/moduleType/';
    if (moduleType) {
      url = url + moduleType;
    }
    return this.http.put(url, payload);
  }

  // Set OBD Settings

  getObdSettingsByModuleType(){
    let url =  this.configService.appConfig.appBaseUrl +  `account/trackerModule/settings?pReq=obd`
    return this.http.get(url);
  }

  setObdSettings(payload:IOBDSettings){
    let url =  this.configService.appConfig.appBaseUrl +  `account/trackerModule/settings/obd`
    return this.http.put(url, payload);
  }

  getAssignmentWindownSettings(moduleType: string): Observable<IAssignmentWindowApiResonse> {
    let url = this.configService.appConfig.appBaseUrl + 'deliveryManagement/settings/assignmentWindow/moduleType/' + moduleType
    return this.http.get<IAssignmentWindowApiResonse>(url)
  }

  putAssignmentWindownSettings(moduleType: string = 'mmd', payload): Observable<IAssignmentWindowApiResonse> {
    let url = this.configService.appConfig.appBaseUrl + 'deliveryManagement/settings/assignmentWindow/moduleType/' + moduleType

    return this.http.put<IAssignmentWindowApiResonse>(url, payload)
  }

  getDeliveryGrpQrCode(deliveryGrpId){
    const url = this.configService.appConfig.appBaseUrl + 'customerOrderUpdate/getQr/deliveryGrpId/' + deliveryGrpId
    return this.http.get(url)
  }
}

// --------------------------------

// utility services

/**
 * Checks if the current view is a mobile view based on the window width.
 * @returns {boolean} - Returns true if the view is a mobile view, false otherwise.
 */
export function isMobileView() {
  return window.innerWidth < MOBILE_BREAK_POINT;
}
// --------------------------------

export interface IOBDSettings{
  isAutoVinUpdates:boolean;
  isAutoReLinkCarriers:boolean;
}

export interface IRoutePlanSettings {
  isRoundTrip: boolean;
  forceCompletionDelivery? : boolean;
  forceCompletionDeliveryDuration? : number;
  defaultRoundTrip: boolean
}

export interface IAssignmentWindowApiResonse {
  enabled: boolean
  window?: Window[]
}

export interface Window {
  start: Start
  end: End
}

export interface Start {
  h: number
  m: number
  ampm?: string
}

export interface End {
  h: number
  m: number
  ampm?: string

}


export const getPopupPill =(label: string,value: string,highlight:string=null)=>{
  if(highlight){
    return `<div
    style="padding: 0px 10px;
    font-weight: 500;
    background-color: rgb(246, 246, 246);
    color:${highlight};
    border-radius: 5px;
    width:fit-content;">
    <p style="margin: 5px 0">${label} <b
    style="border: 1px solid ${highlight};
    padding: 3px 10px;
    border-radius: 7px;"
    >${value}</b></p>
    </div>`
  }
  return `<div
  style="padding: 0px 10px;
  font-weight: 500;
  background-color: rgb(246, 246, 246);
  color:#67726a;
  border-radius: 5px;
  width:fit-content;">
  <p style="white-space: nowrap;margin: 5px 0">${label}<b>${value}</b></p>
  </div>`
}

export const showPopupData=(data)=>{
  let popupHtml=`<div class='popup-body' style="display: grid; gap: 5px;margin-top: 15px;height:${data.length ? 'auto' : '10px'}">`
  for(let idx=0;idx<data.length;idx+=2){
    popupHtml +=
    `<div style="display: flex;gap: 5px;">
    ${data[idx] ?   getPopupPill(data[idx].label,   data[idx].value,  data[idx].color) : ''}
    ${data[idx+1] ? getPopupPill(data[idx+1].label, data[idx+1].value,data[idx+1].color) : ''}
    </div>`
  }
 return popupHtml+ '</div>'
}

export const getPopUpHeader=(label: string,value: string,subheader=null)=>{

  const subheaderUI=`<p style="margin:0;">${subheader}</p>`

  return `
        <div class='tooltip-header'
        style="display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 0px;
        width: 100%;border-bottom: 1px solid rgb(208,208,208);padding-bottom: 7px;
        ">
          <div>
            <h6 style="text-overflow: ellipsis;white-space: nowrap;font-weight:800;margin-bottom: 5px;">${label} ${value}</h6>
            ${subheader ? subheaderUI : ''}
          </div>
        </div>`
}

export const getPopupButtonsAndTip = (tip:string,showButtons=true)=>{

  const buttons =`  <button  class='accept-popup-btn'
  style="padding: 7px 10px;display:inline;
  font-weight: 500;
  border:0;
  background-color: var(--primary-color);
  color:white;
  border-radius: 5px;
  width: max-content;
  height: max-content;
  margin-right: 5px;">
  Update
  </button>
  <button class='reject-popup-btn'
  style="padding: 7px 10px;display:inline;
  font-weight: 500;
  border:1px solid red;
  background-color: white;
  color:red;
  border-radius: 5px;
  width: max-content;
  height: max-content;
  margin-right: 5px;">
  Cancel
  </button>`

  return `<div style='padding:5px 0px;display:flex;justify-content:flex-end'></div><div class='popup-buttons'  style="
  display:flex;justify-content: space-between;
  gap: 10px;margin-top: 7px">
  <div style="display:flex;align-items:center"><span style='border: 1px solid var(--primary-color); color: var(--primary-color); border-radius: 5px; padding: 3px; font-weight: bold'>${tip}</span></div>
  <div>
  ${showButtons ? buttons : ''}
  </div>
  </div>`;
}
