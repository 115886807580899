import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  input,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {cloneDeep, forEach, remove} from 'lodash';
import {Clipboard} from '@angular/cdk/clipboard';
import {OrderStatusColorValuesService} from '../../../../services/order-status-color-values.service';
import {AssetService} from '../../../../services/asset.service';
import {ConfirmationService, LazyLoadEvent, MessageService} from 'primeng/api';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../../../services/config.service';
import {DateFormatterService} from '../../../../services/dateformat';
import {CONSTANT} from '../../../../config/constant';
import {Actions, Subject} from '../../../../auth/rules';
import {Ability} from '@casl/ability';
import moment from 'moment-timezone';
import {NamingConventionFilterPipe} from '../../../../pipes/namingConvention.pipe';
import {AppMainComponent} from '../../../../app/core/main/app.main.component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { filterGeoFencesForConsignment } from '../../../../state/Fleet/fleet.action';
import { Store } from '@ngrx/store';
const MMD_MODULE_NAME = CONSTANT.DMS_MODULES.MMD.MODULE;

@Component({
  selector: 'app-prime-table-row-expansion',
  templateUrl: './prime-table-row-expansion.component.html',
  styleUrls: ['./prime-table-row-expansion.component.scss']
})
export class PrimeTableRowExpansionComponent implements OnInit, OnChanges, OnDestroy {
    CONSTANT_LABEL = CONSTANT.DELIVERY_STATUS.LABELS;
  SUBJECT = Subject;
  ACTIONS = Actions;
  @Input() moduleName: any;
  @Input() orderTableHeaderArray: any = [];
  selectedCarriers: any = [];
  @Input() resetSelectedCarriers: number;
  @Input() carriersMultiSelectBtnConfig: any;
  @Input() orderTableHeaderObj: any = {};
  @Input() autoRefreshBtnConfig: AutoRefreshBtnConfig;
  @Input() sessionStorageName: string;
  @Input() groupedRoutePlansArray
  // @Input() allAssets: any = {};
  // @Input() modifiedData: any;

  // Row Expansion Header configurations
  @Input() autoOpen = false;
  @Input() autoOpenIndex = 1;
  @Input() rowTableHeaderArray: any = [];
  @Input() rowTableHeaderObj: any = {};
  @Input() rowTableData: any = [];
  @Input() rowTableVar: any = {};
  @Input() showHeaderIcons: HeaderIconsVisibility;
  @Input() showHeader: boolean;
  @Input() canEdit: any = {};
  @Input() autoOpenSidebar:boolean
  @Input() pageName: string = '';

  // Emitters
  @Output() sendClickedRowDataToMainComp: EventEmitter<any> = new EventEmitter();
  @Output() sendStopRoundTrip: EventEmitter<any> = new EventEmitter();
  @Output() sendReportDownloadToMainComp: EventEmitter<any> = new EventEmitter();
  @Output() sendIconClickedData: EventEmitter<any> = new EventEmitter();
  @Output() sendEditBtnClickedData: EventEmitter<any> = new EventEmitter();
  @Output() sendDataClickedToMakeAPICall: EventEmitter<any> = new EventEmitter();
  @Output() sendViewRouteBtnClicked: EventEmitter<any> = new EventEmitter();
  @Output() sendQrCodeBtnClicked: EventEmitter<any> = new EventEmitter();
  @Output() sendRadioBtnClicked: EventEmitter<any> = new EventEmitter();
  @Output() sendLazyLoadedObject: EventEmitter<any> = new EventEmitter();
  @Output() sendEmptyLazyLoadedObjectStatus: EventEmitter<boolean> = new EventEmitter();
  @Output() sendAutoRefreshObject: EventEmitter<SentAutoRefreshObj> = new EventEmitter();
  @Output() sendSelectedFieldsForFilter: EventEmitter<SelectedFieldsForFilter> = new EventEmitter();


  @ViewChild('timelineGrid') timelineGrid: ElementRef;

  showRoutePlansFilter:boolean

  disableDelStatusRadioBtn:boolean
  radioButtonsData

  agentDetails = {
    address: '',
    date: ''
  };
  deliveryPerson: any= [];
  timelineOuterContainerWidth: number;
  rowExpandedCount = 1;
  lazyLoadedObject: LazyLoadEvent;
 expandedRows:{[key: string]: boolean} = {};
 collapsedRows:any={};
  // order station radio butoon option holder
  selectedOption: any = null;
  _mmdModuleVar = {
    boxStatusLabel: 'Box Status',
    isMmdModule: false
  };

  seenOrderTableHeaderArray: any = [];
  selectedFilterPillsArray: any = [];
  isCustoToolTipSetting = false;
  stopRoundTripReason = 'Manually closing the trip.';
  onLoad = false;
  @Input() currentGroupById: string | null = '';
  @Input() getEtaDetailsForTimeline:Object

  @Input() highlightedOrderId: string = null;

  selectedRoutePlans;

  deliveryCompletionTypes = [
    { label: 'Delivered', value: 'delivered' },
    { label: 'Force Delivered', value: 'forceDelivered' },
    { label: 'Manually Completed', value: 'manuallyCompleted' },
    { label: 'Time Bound Completed', value: 'timeBoundCompleted' }
  ]
  returnTypes = [
    { label: 'Manually Returned', value: 'isManuallyReturned' },
    { label: 'Auto Returned', value: 'isAutoReturned' }
  ]
  showDeliveryCompletionTypesFilter = input<boolean>(false);
  showReturnTypesFilter = input<boolean>(false);
  selectedDeliveryCompletionTypes: string[];
  selectedReturnType: string = null;
  routePlansIds: string[];
  isCarriersChanged: boolean = false
  isDeliveryCompletionTypeChanged: boolean = false
  carrierFilterObj: SelectedCarrierValue;
  constructor(public assetService: AssetService, public orderStatusColorService: OrderStatusColorValuesService,
              private confirmationService: ConfirmationService, private http: HttpClient, public configService: ConfigService,
              private clipboard: Clipboard, public dateFormatter: DateFormatterService, private ability: Ability,
              private appMain: AppMainComponent,private cd: ChangeDetectorRef,
              private translate: TranslateService,private store:Store,private messageService:MessageService) {

  }

  ngOnInit(): void {
    window.addEventListener('resize', this.refreshOrderedSeenOrderTableHeaderArray);
    this.showRoutePlansFilter= this.moduleName===MMD_MODULE_NAME
  }


  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      const change = changes[propName];
      if (propName === 'rowTableData') {
        if (change.currentValue !== change.previousValue) {
          this.rowTableData.forEach((g)=> {
            g?.orderStatus?.options?.forEach((e)=>{
              if(e?.status === g?.orderStatus?.orderStatus){
                g.orderStatus['selectedOptions'] = e
              }
            })
          })
              // emitt event to open map sidebar of the first element of the data by default
            if (this.rowTableData.length > 0 && !this.onLoad) {
                if(this.autoOpenSidebar){
                  this.appMain.onMenuButtonClick(null)
                  this.sendViewRouteBtnClicked.emit(this.rowTableData[0]);
                  this.currentGroupById = this.rowTableData[0][this.rowTableVar['groupBy']]
                  this.onLoad = true
                }
            }

         this.expandSelectedRows()
          setTimeout(() => {
            this.timelineOuterContainerWidth = this.timelineGrid ? this.timelineGrid.nativeElement.offsetWidth : 200;
          }, 100)

        }
      }
      if (propName === 'orderTableHeaderObj') {
        if (change.currentValue !== change.previousValue) {
          if(this.moduleName && this.moduleName === MMD_MODULE_NAME) {
             this._mmdModuleVar.boxStatusLabel = 'Door Status';
             this._mmdModuleVar.isMmdModule = true;
          } else {
            this._mmdModuleVar.boxStatusLabel = 'Box Status';
             this._mmdModuleVar.isMmdModule = false;
          }
          setTimeout(() => {
            this.setDatesForFilterTypeDateRange();
          }, 100);
         setTimeout(() => {
          this.buildSelectedFilterPillsArray(this.lazyLoadedObject.filters);
         }, 1000)
         this.buildSeenOrderTableHeaderArray();
        }
      }
      if (propName === 'resetSelectedCarriers') {
        if (change.currentValue !== change.previousValue) {
         this.selectedCarriers = [];
         this.carriersChange();
        }
      }
      if (propName === 'groupedRoutePlansArray') {
        if (change.currentValue !== change.previousValue) {
          this.modifyRoutePlansArray()
        }
      }
      if (propName === 'carriersMultiSelectBtnConfig') {
        if (change.currentValue !== change.previousValue) {
          if (this.selectedCarriers && this.carriersMultiSelectBtnConfig) {
            this.selectedCarriers = this.selectedCarriers?.map(selectedCarrier => {
              return this.carriersMultiSelectBtnConfig.find(option => option.name === selectedCarrier.name);
            }).filter(carrier => carrier !== undefined);
          }
        }
      }
    }

  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.refreshOrderedSeenOrderTableHeaderArray);
  }
expandSelectedRows(){
    if(this.autoOpen || this.autoOpenIndex){
        forEach(this.rowTableData,(r, i:number)=> {
          const aI = this.autoOpenIndex - 1;
          this.rowTableData[i].index = i;
          const dId = r.deliveryId;
          const alreadyOpened = !this.expandedRows[dId] && this.autoOpen || aI === i && !this.collapsedRows[dId];
          if (alreadyOpened) {
            this.expandedRows[dId] = this.autoOpen || aI === i;
            const size = Object.keys(this.expandedRows).length;
            this.rowExpandedCount = size;
          }
        })
    }
}

  getOrderedColumnSeenArray() {
    const orderedColumnSeenArray = [];
    for (let i = 0; i < this.orderTableHeaderArray.length; i++) {
      if (
        this.orderTableHeaderObj[this.orderTableHeaderArray[i]['field']][
          'isColumnSeen'
          ]
      ) {
        orderedColumnSeenArray.push(this.orderTableHeaderArray[i]);
      }
    }
    return orderedColumnSeenArray;
  }

  buildSelectedFilterPillsArray(filters){
    const filterPipe = new NamingConventionFilterPipe();
    this.selectedFilterPillsArray = [];
    forEach(filters, (value, key) => {
      const firstObj = value[0];
      switch (firstObj.matchMode) {
        case 'singleSelectFromOptions':
          if (firstObj.value) {
            const pillObj = {
              filterParameterValue: '',
              label: '',
              tooltip: '',
              key: '',
              removeAction: true
            };
            pillObj.filterParameterValue = firstObj.value.filterParameterValue ? firstObj.value.filterParameterValue : null;
            pillObj.label = firstObj.value.name ? firstObj.value.name : null;
            pillObj.key = key;
            pillObj.tooltip = filterPipe.transform(key);
            this.selectedFilterPillsArray.push(pillObj);
          }
          break;
        case 'multiSelectFromOptions':
          forEach(firstObj.value, (obj) => {
            const pillObj = {
              filterParameterValue: '',
              label: '',
              tooltip: '',
              key: '',
              removeAction: true
            };
            pillObj.filterParameterValue = obj.filterParameterValue ? obj.filterParameterValue : null;
            pillObj.label = obj.name ? obj.name : null;
            pillObj.key = key;
            pillObj.tooltip = filterPipe.transform(key);
            this.selectedFilterPillsArray.push(pillObj);
          });
          break;
        case 'groupedMultiSelectFromOptions':
          forEach(firstObj.value,(obj) => {
            const pillObj = {
              filterParameterValue: '',
              label: '',
              tooltip: '',
              key: '',
              removeAction: true
            };
            pillObj.filterParameterValue = obj.filterParameterValue ? obj.filterParameterValue : null;
            pillObj.label = obj.name ? obj.name : null;
            pillObj.key = key;
            pillObj.tooltip = filterPipe.transform(key);
            this.selectedFilterPillsArray.push(pillObj);
          });
          break;
        case 'dateRange':
          if (firstObj.value && firstObj.value.length > 1) {
            const pillObj = {
              filterParameterValue: '',
              label: '',
              tooltip: '',
              key: '',
              removeAction: false
            };
            pillObj.filterParameterValue = firstObj.value[0].filterParameterValue ? firstObj.value[0].filterParameterValue : null;
            let locLabel = '';
            locLabel = moment(firstObj.value[0]).format('DD-MM-YY') + ' - ' + moment(firstObj.value[1]).format('DD-MM-YY');
            pillObj.label = locLabel ? locLabel : null;
            pillObj.key = key;
            pillObj.tooltip = filterPipe.transform(key);
            this.selectedFilterPillsArray.push(pillObj);

          }
          break;
          default:
          break;
      }
    });
  }

  buildSeenOrderTableHeaderArray() {
    this.orderTableHeaderArray = this.translateTableHeaderArray(this.orderTableHeaderArray)
    const sessionColumnDropdownName = this.sessionStorageName + '_columnDropdown';
     if (sessionStorage.getItem(sessionColumnDropdownName)) {
       this.seenOrderTableHeaderArray = cloneDeep(JSON.parse(sessionStorage.getItem(sessionColumnDropdownName)));
       this.refreshOrderedSeenOrderTableHeaderArray('e');
       return;
    }

    forEach(this.orderTableHeaderArray, (value) => {
      if(value.isColumnSeen) {
        this.seenOrderTableHeaderArray.push(value);
      }
    });

    if (this.sessionStorageName) {
      sessionStorage.setItem(sessionColumnDropdownName, (JSON.stringify(this.seenOrderTableHeaderArray)));
    }
  }

  refreshOrderedSeenOrderTableHeaderArray(_) {
    const sessionColumnDropdownName = this.sessionStorageName + '_columnDropdown';
    const dictOfSeenOrderTableHeaderArrayFieldNames = {};
    forEach(this.seenOrderTableHeaderArray, (_, index) => {
      dictOfSeenOrderTableHeaderArrayFieldNames[this.seenOrderTableHeaderArray[index]['field']] = true;
    });

      forEach(this.orderTableHeaderArray, (_, index) => {
        if (this.orderTableHeaderArray[index]['field'] in dictOfSeenOrderTableHeaderArrayFieldNames) {
            this.orderTableHeaderObj={...this.orderTableHeaderObj,
              [this.orderTableHeaderArray[index]['field']]:{
                ...this.orderTableHeaderObj[this.orderTableHeaderArray[index]['field']],
                isColumnSeen:true
              }}
        } else {
          this.orderTableHeaderObj={...this.orderTableHeaderObj,
            [this.orderTableHeaderArray[index]['field']]:{
              ...this.orderTableHeaderObj[this.orderTableHeaderArray[index]['field']],
              isColumnSeen:false
            }}
        }
      });

    if (this.sessionStorageName) {
      sessionStorage.setItem(sessionColumnDropdownName, (JSON.stringify(this.seenOrderTableHeaderArray)));
    }
  }

  getRowColumnSeenArray() {
    const rowColumnSeenArray = [];
    for (let i = 0; i < this.rowTableHeaderArray.length; i++) {
      if (
        this.rowTableHeaderObj[this.rowTableHeaderArray[i]['field']][
          'isColumnSeen'
          ]
      ) {
        rowColumnSeenArray.push(this.rowTableHeaderArray[i]);
      }
    }
    return rowColumnSeenArray;
  }

  confirm(event: Event, data, field) {
    switch (field) {
      case 'customerTrack':
        this.createCustomerLinkMsg(event, data, field);
        break;
      case 'removeOrderAssignment':
        let orderId = '';
        let popupMessage = '';
        if (data.orderId) {
          orderId = data.orderId;
          popupMessage = 'Do you want to remove the order ' + orderId + ' from assignment ?';
        }
        if (data.orderId && data.orderId === CONSTANT.DELIVERY_STATUS.LABELS.RETURNING_TO) {
          popupMessage = 'Do you want to complete the round trip ?';
        }
        this.confirmationService.confirm({
          target: event.target,
          message: popupMessage,
          icon: 'pi pi-info-circle',
          key: 'tableRowExpConfitmPopup',
          accept: () => {
            //confirm action
            this.emitIconClickedData(data, field);
          },
          reject: () => {
            //reject action
          }
        });
        break;
      default:
        break;
    }
  }

  pillRemoved(pill) {
    this.lazyLoadedObject.filters = (this.removeFilterManually(pill, this.lazyLoadedObject.filters));

    const sessionStoredData = JSON.parse(sessionStorage.getItem(this.sessionStorageName));
    if (sessionStoredData && sessionStoredData['filters']) {
      sessionStoredData['filters'] = (this.removeFilterManually(pill, sessionStoredData['filters']));
      sessionStorage.setItem(this.sessionStorageName, (JSON.stringify(sessionStoredData)));
    }

    this.sendLazyLoadedObject.emit(this.lazyLoadedObject)
  }

  removeFilterManually(pill, filters){
    const selctedFieldToRemove = filters[pill.key][0];
    switch (selctedFieldToRemove.matchMode) {
      case 'singleSelectFromOptions':
        if (selctedFieldToRemove.value.filterParameterValue === pill.filterParameterValue) {
          selctedFieldToRemove.value = null;
        }
        break;
      case 'multiSelectFromOptions':
        remove(selctedFieldToRemove.value, function(n: any) {
          return (n.filterParameterValue === pill.filterParameterValue);
        });
        break;
      case 'groupedMultiSelectFromOptions':
        remove(selctedFieldToRemove.value, function(n: any) {
          return (n.filterParameterValue === pill.filterParameterValue);
        });
        break;
      default:
      break;
    }

    return filters;
  }

  clickableDataIsClicked(data) {
    this.currentGroupById = data[this.rowTableVar['groupBy']]
    this.highlightedOrderId = data?.orderId
    this.sendClickedRowDataToMainComp.emit(data);
  }

  emitIconClickedData(data, field){
    data['iconFieldName'] = field
    this.sendIconClickedData.emit(data)
  }

  // emitDataToDelete(data){
  //   this.sendDataToDelete.emit(data)
  // }
  dataClickedToMakeApiCall(data){
    this.sendDataClickedToMakeAPICall.emit(data)
  }

  editBtnClicked(data){
    this.sendEditBtnClickedData.emit(data);
  }
  viewRouteBtnClicked(data,status){
     console.log(status)
    data.openCompilanceEventsDialogNumber = (!!status ? (Math.random()) : (-1*Math.random()));

    this.currentGroupById = data[this.rowTableVar['groupBy']];
    this.highlightedOrderId = null;
    this.store.dispatch(filterGeoFencesForConsignment({consignmentId:data[this.rowTableVar['groupBy']]}))
    this.sendViewRouteBtnClicked.emit(data);
  }
  viewQrCodeBtnClicked(data){
    this.sendQrCodeBtnClicked.emit(data);
  }
  downloadReport(data,format){
    data['format'] = format;
    this.sendReportDownloadToMainComp.emit(data);
  }
  isItemString(item){
    if(typeof item === 'string'){
      return true;
    }
    return false;
  }
  isItemObject(item){
    if(item instanceof Object){
      return true;
    }
    return false;
  }

  onRowExpand(e){
    const dId = e.data.deliveryId;
    this.collapsedRows[dId] = false;
    ++this.rowExpandedCount;
  }

  onRowCollapse(e) {
    const dId = e.data.deliveryId;
    --this.rowExpandedCount;
    this.collapsedRows[dId] = true;
    if (this.rowExpandedCount === 0 && !this.showHeader) {
       this.showHeader = false;
    }
  }
  orderStatusStyle(status, field){
    const styleObj = this.orderStatusColorService.getTextAndBorderColor(status, field);
    styleObj['border-radius'] = '16px';
    styleObj['background-color'] = 'transparent';

    return styleObj

  }
  onRadioBtnClick(status, rowData){
    this.sendRadioBtnClicked.emit({status: status, id:rowData.id});
    this.selectedOption = null;
  }

  loadItems(event: LazyLoadEvent) {
    this.lazyLoadedObject = event;
    const date = this.lazyLoadedObject?.filters?.expectedDeliveryDateTimeDetails?.[0]?.value
    this.buildSelectedFilterPillsArray(this.lazyLoadedObject.filters);
    if (this.checkIfStartDateAndEndDatePresent()) {
      this.sendLazyLoadedObject.emit(this.lazyLoadedObject)
    } else if(!date?.includes(null)){
      this.sendEmptyLazyLoadedObjectStatus.emit(true)
    }
 }

  setDatesForFilterTypeDateRange(){
    forEach(this.orderTableHeaderObj, (value, key) => {
      const fType = value && value.filterMenuType;
      if ( fType && fType === 'dateRange') {
      if(this.lazyLoadedObject['filters'][key]){
        if (!this.lazyLoadedObject['filters'][key][0]['value']) {
          this.lazyLoadedObject['filters'][key][0]['value'] = [];
        }
        this.lazyLoadedObject['filters'][key][0]['value'].push(value.startDate);
        this.lazyLoadedObject['filters'][key][0]['value'].push(value.endDate);
      }
      }
    });
  }


  checkIfStartDateAndEndDatePresent() {
    let isPresent = true;
    forEach(this.orderTableHeaderObj, (value, key) => {
      const fType = value.filterMenuType && value.filterMenuType;
      if ( fType === 'dateRange') {
      if(this.lazyLoadedObject['filters'][key] && Array.isArray(this.lazyLoadedObject['filters'][key]) && this.lazyLoadedObject['filters'][key][0]['value'] && this.lazyLoadedObject['filters'][key][0]['value'].length > 1
      && this.lazyLoadedObject['filters'][key][0]['value'][0] && this.lazyLoadedObject['filters'][key][0]['value'][1] !== null){
        isPresent = true;
      } else {
        isPresent = false;
      }
      }
    });
    return isPresent;
  }

  getAssetLocation(e) {
    this.agentDetails.address = '';
    this.agentDetails.date = '';
    if(e.agentId) {
      this.assetService.geoEncodedAssetLocation(e.agentId).subscribe((data: any) => {
        if (data && data.address) {
          this.agentDetails.address = data.address;
          if (data && data.date) {
            this.agentDetails.date = this.dateFormatter.convertDateToSelectedTimezone(data.date);
          }
        } else {
          this.agentDetails.address = 'No Address Found';
        }
      }, () => {
        this.agentDetails.address = 'No Address Found';
      })
    }
  }

  customerTrackUrl: string;
  createCustomerLinkMsg(event: Event, data, field) {
    if(data.id) {
      this.getCustomerTrackLink(data.id).subscribe((res: any) => {
        this.customerTrackUrl = res.url ? res.url : ''
        const customerTrackMsg = `Are you sure you want to send tracking link ?
        </br>
        <a href="${res.url ? res.url : '#'}" target="_blank" style="text-decoration: underline">Tracking Link</a>
        <button id="copyBtn" pbutton icon="pi pi-copy" pTooltip="Copy" tooltipPosition="top" class="p-element p-button p-component p-button-icon-only" style="margin-left: 5px;">
        <span class="p-button-icon pi pi-copy" aria-hidden="true"></span>
        </button>
        `;

          this.confirmationService.confirm({
            target: event.target,
            message: null,
            key: 'tableRowExpConfitmPopup',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.emitIconClickedData(data, field);
            },
            reject: () => {
            }
        });
        setTimeout(() => {
          document.querySelector('.p-confirm-popup-message').innerHTML = `${customerTrackMsg}`;
          document.getElementById('copyBtn').onclick = () => {
            this.copyActionMessage(this.customerTrackUrl);
          }
        }, 10)
      })
    }
  }

  copyActionMessage(textToCopy: string) {
    this.messageService.clear();
    this.clipboard.copy(textToCopy);
    this.messageService.add({key:'tableRowExpNotification', severity: 'info', detail: 'Link Copied Successfully...!' });
  }

  getCustomerTrackLink(id) {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'deliveryManagement/management/customerLink/' + id);
  }

  showFilterPills:boolean
  showSelectButton:boolean

  carriersChange(isEmitFiltersNeeded: boolean = true) {
    const slectedValues: SelectedCarrier[] = [];
    forEach(this.selectedCarriers, function(value) {
      const obj: SelectedCarrier = {
        filterParameterValue: null,
        name: null
      };
      value._id ? obj.filterParameterValue = value._id : obj.filterParameterValue = null ;
      value.name ? obj.name = value.name : obj.name = null;
      slectedValues.push(obj);
    });

    const carrierFilterObj: SelectedCarrierValue = {
      value: slectedValues
    };
    this.carrierFilterObj = { ...carrierFilterObj };
    if (isEmitFiltersNeeded && this.isCarriersChanged) {
      this.emitSelectedFieldsForFilter();
    }
    this.isCarriersChanged = false;
  }

  canUpdateState() {
    let canCreate: boolean;
     canCreate = false;
     if ( this.ability.can( Actions.READ, Subject.MMD_MANAGEMENT)) {
       if (this.ability.can( Actions.WRITE, Subject.MMD_MANAGEMENT)) {
         canCreate = true;
       }
     }
     return canCreate;
  }

  checkIfValueIsNotRoundTripLabel(value) {
    const satus = true;
    if (value === CONSTANT.DELIVERY_STATUS.LABELS.RETURNING_TO
      || value === CONSTANT.DELIVERY_STATUS.LABELS.ROUNDTRIP ||
      value === CONSTANT.DELIVERY_STATUS.LABELS.RETURNED) {
      return false;
    }
    return satus;
  }

  cancelTrip(v) {
    const cancelObj = {
      'id': v?.deliveryId,
      'reason': this.stopRoundTripReason
    };
    this.sendStopRoundTrip.emit(cancelObj);
  }


  customRadiotButtonClicked(data) {
    this.emitIconClickedData(data, 'removeOrderAssignment');
  }

  // private canEditByDeliveryId(dId: string) {
  //   const that = this;
  //   this.dmsAssignmentService.checkCanEditOrderStatus(dId).subscribe(async (data) => {
  //     that.canEdit[dId] = data?.['canEdit'];
  //   });
  // }

  getAddCarrInfo(data){
    let result=[]
    data && Object.keys(data)?.forEach(val=>{
      result.push({'label':val,'value':data[val]})
    })
    return result
  }

  //TODO: need to confirm about this method
  setup(a,b){
    if(a&&b){

    }
  }

  translateTableHeaderArray(arr){
    const translate = new TranslatePipe(this.translate, this.cd);
    let localArr = [...arr];
    localArr.forEach((value,idx)=>{
      let localVal = {...value}
      let label = translate.transform(localVal['header'])
      localVal['header'] = label
      localVal['uId'] = idx
      localArr[idx] = {...localVal}
    })
    return localArr
  }

  showTempTooltip(expansionData){
    let str = 'Temperature';
    if (expansionData.temp && expansionData?.sensorLastUpdated)
    str = str + ' updated : ' + expansionData?.sensorLastUpdated;
    return str;
  }

  latestTime(expansionData){
    return expansionData?.sensorLastUpdated || '';
  }

  showTooltip(filterFieldName, data){
     let tT = '';
     switch (filterFieldName) {
      case "tempDetails":
        tT = data?.tempDetailsTooltip ?? ''
        break;
      case "orderStatus":
         if (data?.orderId === CONSTANT.DELIVERY_STATUS.LABELS.RETURNED) {
          tT = 'Trigger Type'
        }
        break;
      default:
        break;
    }
     return tT;
  }

  autoRefreshToggle(_){
    const obj: SentAutoRefreshObj = {
     checked: this.autoRefreshBtnConfig.checked,
     isNewDataToLoadReq: this.autoRefreshBtnConfig.checked ?? false
    }

    if (this.sessionStorageName) {
      const sessionARName = this.sessionStorageName + '_autoRefresh'
      sessionStorage.setItem(sessionARName, (this.autoRefreshBtnConfig.checked).toString());
    }

    this.sendAutoRefreshObject.emit(obj);
   }

  autoRefreshBtnClicked() {
     const obj: SentAutoRefreshObj = {
       checked: this.autoRefreshBtnConfig.checked,
       isNewDataToLoadReq: true
      }
     this.sendAutoRefreshObject.emit(obj);
   }

   alertNotificationBtnClicked(){
    const obj: SentAutoRefreshObj = {
      checked: false,
      isNewDataToLoadReq: false,
      isAlertNotificationBtn: true,
     }
    this.sendAutoRefreshObject.emit(obj);
   }

   modifyRoutePlansArray(){
     this.groupedRoutePlansArray = this.groupedRoutePlansArray?.map((rPlanGrp, pIdx) => {

       const grpChildren = rPlanGrp?.children?.map((rPlanChild, cIdx) => {
         return { ...rPlanChild, key: pIdx + cIdx + Math.random() }
      })
       return { ...rPlanGrp, children: grpChildren, key: pIdx + Math.random() }
    })
   }

   manageFilterPanelHeight:boolean

  filterByRoutePlans(rPlans, isEmitFiltersNeeded: boolean = true) {
    const rPlansIds= []
    rPlans?.forEach(r=>{

      r?.filterParameterValue &&
      rPlansIds.push(r.filterParameterValue)

    })
    this.routePlansIds = [...rPlansIds];
    if (isEmitFiltersNeeded)
      this.emitSelectedFieldsForFilter();
   }

   clearFilters() {
    this.selectedRoutePlans = [];
    this.selectedCarriers = [];
    this.selectedDeliveryCompletionTypes = [];
    this.selectedReturnType = null;
    this.filterByRoutePlans(this.selectedRoutePlans, false);
    this.carriersChange(false)
    this.emitSelectedFieldsForFilter();
  }

  deliveryCompletionTypesChange() {
    if (this.isDeliveryCompletionTypeChanged) {
      this.emitSelectedFieldsForFilter();
    }
    this.isDeliveryCompletionTypeChanged = false;
  }

  emitSelectedFieldsForFilter() {
    const selectedFieldsForFilterObj: SelectedFieldsForFilter = {
      selectedCarriers: this.carrierFilterObj,
      selectedRoutePlans: this.routePlansIds,
      selectedDeliveryCompletionTypes: this.selectedDeliveryCompletionTypes,
      selectedReturnType: this.selectedReturnType
    }
    this.sendSelectedFieldsForFilter.emit(selectedFieldsForFilterObj)
  }

}


export interface HeaderIconsVisibility {
  humidity: boolean;
  temp: boolean;
  boxStatus: boolean;
  view: boolean;
  edit: boolean;
  address: boolean;
  report: boolean;
  forceStopRoundTrip?: boolean;
  showComplianceEvents?: boolean;
  qrCode: boolean;
  lastUpdated: boolean;
}

export interface AutoRefreshBtnConfig {
  checked: boolean;
  showRefreshIcon: boolean;
  showEnableBtn: boolean;
  newConsignmentCount: number;
  showAlertNotificationBtn?: boolean
}

export interface SentAutoRefreshObj {
  checked: boolean;
  isNewDataToLoadReq: boolean;
  isAlertNotificationBtn?: boolean;
}

export interface SelectedDeliveryAndReturnTypes {
  selectedDeliveryCompletionTypes: string[]
  selectedReturnType: string
}

export interface SelectedFieldsForFilter {
  selectedRoutePlans: string[]
  selectedCarriers: SelectedCarrierValue
  selectedDeliveryCompletionTypes: string[]
  selectedReturnType: string
}

export interface SelectedCarrierValue {
  value: SelectedCarrier[]
}
export interface SelectedCarrier {
  filterParameterValue: string,
  name: string
}