<!-- <ul id='timeline'>
<span *ngFor="let lData of timelineOuterLeftData" class="leftGrid smallGreyText" [pTooltip]="lData.placeholder" tooltipPosition="top">
  {{lData.value}}
</span>
<li class='event' *ngFor="let data of timelineData; let i = index"
  [ngClass]="{ 'last-list': i === timelineData.length-1 }"
  [ngStyle]="{'border-color': data?.lineActiveColor, 'minWidth': (data.valueBtwCircles === 0 || data.valueBtwCircles) ? setDistanceLineWidth(data.valueBtwCircles) : '150px'}">
  <div class='content'>
  </div>

  <div class="dot cursorPointer" (click)="op.toggle($event); cirlceClicked(data);">
    <span class='circle' [ngStyle]="{'border-color': data?.circleActiveTextColor, 'color': data?.circleActiveTextColor }">{{data?.valueInCircle}}</span>
  </div>
  <div class='date'>{{data?.textBtwCircles}}</div>
</li>
<span *ngFor="let rData of timelineOuterRightData" class="rightGrid" [pTooltip]="rData.placeholder" tooltipPosition="top">
  {{rData.value}}
</span>
</ul>

<p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '450px'}">
  <div *ngFor="let data of timelineData">
    <p-card header="" [style]="{'width': '100%', 'margin': '5px 0'}" *ngIf="showClickedContentValue === data.valueInCircle">
      <ng-template pTemplate="header"></ng-template>
      <p *ngFor="let content of data.contents">
        <span style="margin-right: 3px;">{{content.label}} <span *ngIf="content.label">:</span> </span>
        <span style="font-weight: 500" [ngClass]="{'custom-badge ' : content?.id}" [ngStyle]="orderStatuColorService.getTextAndBorderColor(content.id, content.field)">{{content.value}}</span>
      </p>
    </p-card>
  </div>
</p-overlayPanel> -->
<div class="timeline-container">
  @for (lData of timelineOuterLeftData; track lData.value) {
    <p
      [pTooltip]="lData.placeholder"
      tooltipPosition="top"
      class="timeline-left-text"
    >{{ lData.value }}</p>
  }
  <ul id='timeline'>
    @for (data of timelineData; track data.trackId; let i = $index) {
      <li class='event'
          [ngClass]="{ 'last-list': i === timelineData.length-1 }"
          [ngStyle]="{'border-color': data?.lineActiveColor, 'width': (data.valueBtwCircles === 0 || data.valueBtwCircles) ? setDistanceLineWidth(data.valueBtwCircles) : '150px'}">
        @if (extractedEtaDetails?.[data?.valueInCircle]) {
          <div class='content-eta-info' [ngStyle]="(i === timelineData.length-1) && {'display':'none'}"
               [pTooltip]="'ETA : ' +extractedEtaDetails?.[data?.valueInCircle]" tooltipPosition="top">
            <p>{{ extractedEtaDetails?.[data?.valueInCircle] }}</p>
          </div>
        }
        @if (extractedEtaDetails?.[data?.valueInCircle]) {
          <div class='content-eta-info-indicator' [ngStyle]="(i === timelineData.length-1) && {'display':'none'}">
          </div>
        }
        <div class="dot cursorPointer"
             (click)="overlayPanelVisible && op.hide();!overlayPanelVisible && op.show($event); cirlceClicked(data);">
        <span class='circle' [ngClass]="{'no-background': data?.dataMissing }" [ngStyle]="{'border-color': data?.circleActiveTextColor,
                 'color': data?.circleActiveTextColor }">{{data?.valueInCircle}}
        </span>
        <!-- TO DO Remove the order status check to draw temp non comp badge once backend fixes it -->
        @if (data?.nonComplianceTempEvents?.length && (data?.status === "delivered" || data?.status === "inProgress" ||
        data?.status === "pickedUp" || data?.status === "autoReturned" || data?.status === "returningTo")) {
        <p-badge class='eventsBadge'
          [value]="(data?.nonComplianceTempTotalEvents > 99) ? '99+' : data?.nonComplianceTempTotalEvents" severity="danger"
          (mouseenter)="buildNonComplianceTempTooltip(data)" [pTooltip]="nonComplianceTempTooltip" [escape]="false"
          tooltipPosition="top" />
        }
        </div>
        <div [pTooltip]="data?.distanceTooltip || ''" tooltipPosition="bottom" class='date'
             [ngStyle]="(i === timelineData.length-1) && {'display':'none'}">{{ data?.textBtwCircles }}
        </div>
      </li>
    }
  </ul>
  @if(statusInitial()){
  <!--  TO DO Remove the order status check to draw Delivery type badge once backend fixes it -->
    <p pBadge [value]="statusInitial()" class="order-status-info"
      [ngClass]="{ 'singleDigitEvent': (returnTempTotalEventAndStatus()?.returnTempTotalEvent > 0 && returnTempTotalEventAndStatus()?.returnTempTotalEvent < 10 && returnTempTotalEventAndStatus()?.status === 'autoReturned'), 'twoDigitEvent': (returnTempTotalEventAndStatus()?.returnTempTotalEvent > 9 && returnTempTotalEventAndStatus()?.returnTempTotalEvent < 100 && returnTempTotalEventAndStatus()?.status === 'autoReturned'), 'threeDigitEvent': (returnTempTotalEventAndStatus()?.returnTempTotalEvent > 99 && returnTempTotalEventAndStatus()?.status === 'autoReturned') }"
      [style]="{'--status-color':(childStatus() | statusToColorPipe)}" tooltipEvent="hover"
      [pTooltip]="(childStatus() | namingConvention)" tooltipPosition="top">
      </p>
      }
  <div class="timeline-right-container">
    @for (rData of timelineOuterRightData; track rData.value; let j = $index) {
      <p
        [attr.data-status]="rData.placeholder"
        [ngStyle]="j === 0? {'margin-left':'0'}:{'margin-left':'12px'}"
        [pTooltip]="rData.placeholder"
        class="timeline-right-text"
        tooltipPosition="top">
      {{rData.value}}</p>
    }
  </div>
</div>

<p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '400px'}" (onHide)="overlayPanelVisible=false"
  (onShow)="overlayPanelVisible=true">
  <!--   @for (data of timelineData; track data.valueInCircle) {
    <div>
      @if (showClickedContentValue === data.valueInCircle) {
        <div header="" [style]="{'width': '100%', 'margin': '5px 0'}">
          @for (content of data.contents; track content.label) {
            <p>
              <span style="margin-right: 3px;">{{ content.label }} @if (content.label) {
                <span>:</span>
              } </span>
              <span style="font-weight: 500" [ngClass]="{'custom-badge ' : content?.id}"
                    [ngStyle]="orderStatuColorService.getTextAndBorderColor(content.id, content.field)">{{ content.value }}</span>
            </p>
          }
        </div>
      }
    </div>
  } -->
  @for (data of timelineData; track data) {
  <div class="order-overlay-tooltip-own">
    @if (showClickedContentValue === data.valueInCircle) {
    <div class="order-overlay-popup-content">
      <div class="tooltip-content">
        <div class="tooltip-header"
          [ngClass]="{'tooltip-header-borderBottom': data?.nonComplianceTempEvents?.length && (data?.status === 'delivered' || data?.status === 'inProgress' || data?.status === 'pickedUp' || data?.status === 'autoReturned' || data?.status === 'returningTo')}">
          <div class="tooltip-header-left">
            @if (data?.orderDetails?.pickupLocation){<h6 [pTooltip]="data?.orderDetails?.pickupLocation?.label"
              tooltipPosition="left">{{data?.orderDetails?.pickupLocation?.value}}</h6>}
            @if (data?.orderDetails?.deliveryCenter){<h6 [pTooltip]="data?.orderDetails?.deliveryCenter?.label"
              tooltipPosition="left">{{data?.orderDetails?.deliveryCenter?.value}}</h6>}
            @if (data?.orderDetails?.returnLocation){<h6 [pTooltip]="data?.orderDetails?.returnLocation?.label"
              tooltipPosition="left">{{data?.orderDetails?.returnLocation?.value}}</h6>}
            <p [pTooltip]="data?.orderDetails?.consignmentNo?.label" tooltipPosition="left">
              {{data?.orderDetails?.consignmentNo?.value}}</p>
            <p [pTooltip]="data?.orderDetails?.delvieryGrpSequence?.label" tooltipPosition="left">
              {{data?.orderDetails?.delvieryGrpSequence?.value}}</p>
          </div>
  
          <div class="tooltip-header-right">
            @if (data?.orderDetails?.orderStatus?.value){
            <div
              [ngStyle]="orderStatuColorService.getTextAndBorderColor(data?.orderDetails?.orderStatus?.id, data?.orderDetails?.orderStatus?.field)"
              [pTooltip]="data?.orderDetails?.orderStatus?.label" tooltipPosition="left" class="online">
              {{data?.orderDetails?.orderStatus?.value}}
            </div>
            }
            @if (data?.orderDetails?.statusDate?.value){
            <div [pTooltip]="data?.orderDetails?.statusDate?.label" tooltipPosition="left" class="statusDate">
              {{data?.orderDetails?.statusDate?.value}}
            </div>
            }
            @if (data?.orderDetails?.pickupDate?.value){
            <div [pTooltip]="data?.orderDetails?.pickupDate?.label" tooltipPosition="left" class="statusDate">
              {{data?.orderDetails?.pickupDate?.value}}
            </div>
            }
            @if (data?.orderDetails?.returnedDate?.value){
            <div [pTooltip]="data?.orderDetails?.returnedDate?.label" tooltipPosition="left" class="statusDate">
              {{data?.orderDetails?.returnedDate?.value}}
            </div>
            }
          </div>
        </div>
        @if (data?.nonComplianceTempEvents?.length && (data?.status === "delivered" || data?.status === "inProgress" ||
        data?.status === "pickedUp" || data?.status === "autoReturned" || data?.status === "returningTo")) {
        <div class="tooltip-body">
        @for (event of data?.nonComplianceTempEvents; track event.name) {
          <div class="sensor-pill">
            <p pTooltip="Temp Non-Compliance" tooltipPosition="top">{{ event.name }} : <b>{{ event.count }}</b></p>
          </div>
          }
        </div>
      }
      </div>
    </div>
    }
  </div>
  }
</p-overlayPanel>
