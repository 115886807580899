import { Injectable } from '@angular/core';
import { DateFormatterService } from '../../../services/dateformat';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../../services/config.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(
    private dateFormatterService: DateFormatterService,
    private http: HttpClient, 
    private configService: ConfigService
  ) { }

  getSchedulsDateByType(schedules,key:string,cities,locations,rplans,vehicles){
     if(key==='all'){
      return schedules.map(sc=>this.formatSchedulesData(sc,cities,locations,rplans,vehicles))
     }
     if(key==='today'){

      const todaySchedules = [];
      (schedules || []).forEach(schedulesWithDate => {
        const formattedDate = schedulesWithDate.formattedDate + ` (${this.dateFormatterService.getDateComponents(schedulesWithDate['date'])?.['dayOfWeek']})`;
        (schedulesWithDate?.scheduleData || []).forEach(sch => {
          todaySchedules.push({
            ...sch,
            formattedDate,
            startDateForPause: schedulesWithDate['date'],
            endDateForPause: schedulesWithDate['date'],
            combinedCount:schedulesWithDate.count
          })
        })
      })
      return todaySchedules.map(sc=>this.formatSchedulesData(sc,cities,locations,rplans,vehicles))
     }
  }


  formatSchedulesData(schedule, citiesList, locationsList, routePlansList,vehicles) {
    const startDateLabel = schedule.startDate ? this.getDateConvertor(schedule.startDate) : ''
    const endDateLabel = schedule.scheduledDate ? '' : (schedule.endDate && (schedule.endDate !== 'Until Cancel')) ? this.getDateConvertor(schedule.endDate) : 'Until Cancel'

    const recurringDateRange = startDateLabel + ' - ' + endDateLabel

    const scheduleTypeLabel = (schedule.scheduleType !== 'recurring') ? 'One Time' : (this.toTitleCase(schedule.scheduleType) + ` (${this.toTitleCase(schedule?.recurringType)})`)
    const scheduledDateLabel = schedule.scheduledDate ? (this.getDateConvertor(schedule.scheduledDate) + ' - ' + this.dateFormatterService.convertTo12HourFormat(schedule.scheduledTime)) : this.dateFormatterService.convertTo12HourFormat(schedule.scheduledTime)


    return ({
      ...schedule,
      scheduleType: (schedule.scheduleType === 'single') ? 'One Time' : schedule.scheduleType,
      recurringType: (schedule.scheduleType === 'recurring') ? (schedule?.recurringType) : '',

      recurringDateRange: recurringDateRange,
      scheduleTypeLabel: scheduleTypeLabel,
      scheduledDateLabel: scheduledDateLabel,
      scheduledTimeLabel: this.dateFormatterService.convertTo12HourFormat(schedule.scheduledTime),

      startDate: schedule.startDate ? this.dateFormatterService.convertDateToSelectedTimezone(schedule.startDate) : '',

      created: schedule.created ? (this.isISOFormat(schedule.created) ? this.getDateConvertor(schedule.created, true) : schedule.created) : '',

      endDate: schedule.scheduledDate ? '' : (schedule.endDate && (schedule.endDate !== 'Until Cancel')) ? this.dateFormatterService.convertDateToSelectedTimezone(schedule.endDate, 'll') : '',

      scheduledDate: schedule.scheduledDate ? this.dateFormatterService.convertDateToSelectedTimezone(schedule.scheduledDate) : '',

      pickupCity: citiesList.find(city => city._id === schedule.pickupCityId)?.city || '',
      pickupLocation: locationsList.find(location => location._id === schedule.pickupLocationId)?.name || '',
      routePlans: schedule.routePlans ? schedule.routePlans : this.getRoutePlansLabels(schedule.routePlanIds, routePlansList),
      carrier:vehicles.find(c => c._id === schedule.agentId)?.name || '',
      editAsset: 'Edit',
      status: schedule.isExpired ? 'Expired' : schedule.isPaused ? 'Paused' : 'Active',
      actionMethods: this.getActionMethods(schedule.isPaused, schedule.isExpired),

      daysLabels: schedule?.recurringType === 'monthly' ? schedule.dates : schedule?.recurringType === 'weekly' ? schedule.days : []
    })
  }

  getDateConvertor(date, showTime = false) {
    let cDate = this.dateFormatterService.convertDateToSelectedTimezone(date, 'DD/MM/YY');
    if (showTime)
      cDate = cDate + ',' + ' ' + this.dateFormatterService.getFormattedTimeSlotForSingleTime(date, 'LTS');
    return cDate
  }

  getActionMethods(isPaused:boolean, isExpired:boolean) {
    const actions = ['Edit','Delete']
    !isExpired && actions.push(isPaused ? 'Resume' : 'Pause')
    return actions
  }

  getRoutePlansLabels(routePlanIds: string[], routePlansList) {
    const categories = {}
    routePlansList.forEach(rPlan => {
      if (routePlanIds?.includes(rPlan._id)) {
        categories[rPlan.category] = categories[rPlan.category] ? (categories[rPlan.category] + ',' + rPlan.label) : rPlan.label
      }
    })
    let label = '';
    for (const cat in categories) {
      label += cat + ': ' + categories[cat] + ';'
    }
    return label
  }

  isISOFormat = (dateString) => {
    const parsedDate = Date.parse(dateString);
    return !isNaN(parsedDate) && dateString === new Date(parsedDate).toISOString();
  };

  toTitleCase(str: string) {
    const words = str.toLowerCase().split(' ');
    const titleCaseWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);

    });
    return titleCaseWords?.join(' ') || '';
  }

  addNewSchedule(schedule) {
    return this.http.post(this.configService.appConfig.appBaseUrl + 'deliveryManagement/assignment/group/schedule', schedule);
  }

  editSchedule(scheduleId:string,schedule) {
    return this.http.put(this.configService.appConfig.appBaseUrl + 'deliveryManagement/assignment/group/schedule/id/' + scheduleId, schedule);
  }

  deleteSchedule(scheduleId:string) {
    return this.http.delete(this.configService.appConfig.appBaseUrl + 'deliveryManagement/assignment/group/schedule/id/' + scheduleId);
  }

  pauseSchedule(scheduleIds: string,duration,pauseUpcoming=false) {
    return this.http.put(this.configService.appConfig.appBaseUrl + 'deliveryManagement/assignment/group/schedule/pause/id/' + scheduleIds+`?pauseUpcoming=${pauseUpcoming}`, duration);
  }
  unPauseSchedule(scheduleId: string) {
    return this.http.put(this.configService.appConfig.appBaseUrl + 'deliveryManagement/assignment/group/schedule/unpause/id/' + scheduleId,{});
  }
}
