import {createReducer, on} from '@ngrx/store';
import {cloneDeep, forEach} from 'lodash';
import moment from 'moment';
import {IMapIcons, TrackerMapIconsSettings} from '../../app/settings/settings-models';
import {CONSTANT} from '../../config/constant';
import {GeoFenceObject, GpsData, TrackerIcons, VehicleObject} from './models/fleet.models';
import {
  changePlotGeoFencesStatus,
  clearEventsDataForAllVehicles,
  clearEventsSubTypesDataForAllVehicles,
  failure,
  filterGeoFencesForConsignment,
  getActualPathsForCarrierSuccess,
  getActualPathsForCarrierWithoutApiCall,
  getCarGpsHistoricalData,
  getCarGpsHistoricalDataFaliure,
  getCarGpsHistoricalDataSuccess,
  getEventsForVehicleSuccess,
  getEventsSubTypesForVehicleSuccess,
  getExpectedPathsForCarrierSuccess,
  getExpectedPathsForCarrierWithoutApiCall,
  getLinkedLocationsSuccess,
  getMapIconsSettingsSuccess,
  loadAssetsLinkedDataSuccess,
  loadAssignedOrdersGroupDataSuccess,
  loadDistanceMovedSuccess,
  loadEventsDataCountSuccess,
  loadEventsDataSuccess,
  loadEventStatusDataSuccess,
  loadFuelDataSuccess,
  loadGeoAssetsByIdSuccess,
  loadGeofencesSuccess,
  loadMarkerIconsSuccess,
  loadSelectedTrackableVehiclesSuccess,
  loadSensorDataSuccess,
  loadSpeedDataSuccess,
  loadVehiclesSuccess,
  resetExpectedAndActualPath,
  resetSelectedVehicle,
  resetTrackState,
  setLocationIdsForGeoFenceFiltering,
  setSelectedGroup,
  updateGeoFenceById,
  updateGeofencesSuccess,
  updateMapIconObjectField,
  updateMapIconSettingsSuccess,
  updateVehicleGpsData,
  updateVehicleInfoSuccess
} from './fleet.action';
import {LatLngExpression} from 'leaflet';
import {EventData, EventStatus, GeoAsset, GraphData, SensorData} from './models/custom.types';
import {ILocation} from '../Assets/interfaces/IAsset.types.modal';


export interface FleetState {
  selectedVehicle: VehicleObject;
  selectedVehicleAssetData: VehicleObject;
  selectedGroupName: string;
  allVehicles: Record<string, VehicleObject[]>;
  selectedTrackableVehicles: Record<string, VehicleObject[]>;
  lastUpdatedVehicle: GpsData;
  distanceMovedData: GraphData;
  trackIcons: Record<string, TrackerIcons>;
  speedData: GraphData;
  fuelData: GraphData;
  sensorData: SensorData;
  eventsCount: { count: number };
  eventsData: Array<EventData>;
  eventsStatusData?: EventStatus;
  geofences: GeoFenceObject[];
  filteredGeofences: GeoFenceObject[];
  status: string;
  error: Error;
  mapIconData: IMapIcons[];
  geoAssets: GeoAsset[];
  successMsg: { msg: string },
  expectedPathLatLngs: LatLngExpression[],
  actualPathLatLngs: LatLngExpression[],
  linkedLocations: Record<string, ILocation[]>,
  geoFenceFilterIds: Record<string, string[]>;
  plotGeoFencesStatus: boolean;
  carGpsHistoricalData: any;
  eventsDataByVehicle: Record<string, any>,
  eventSubTypesDataByVehicle: Record<string, any>
  loaderForGetCarGpsHistoricalData: boolean
}

export const initialState: FleetState = {
  selectedVehicle: null,
  selectedGroupName: null,
  allVehicles: null,
  selectedTrackableVehicles: null,
  lastUpdatedVehicle: null,
  distanceMovedData: null,
  speedData: {
    name: 'Speed',
    xAxis: [],
    yAxis: []
  },
  fuelData: {
    name: 'Fuel',
    xAxis: [],
    yAxis: []
  },
  sensorData: {
    temperature: {
      name: 'Temperature',
      xAxis: [],
      yAxis: []
    },
    humidity: {
      name: 'Humidity',
      xAxis: [],
      yAxis: []
    }
  },
  eventsCount: null,
  eventsData: null,
  eventsStatusData: null,
  geofences: null,
  filteredGeofences:null,
  status: 'pending',
  error: null,
  mapIconData: null,
  geoAssets: null,
  successMsg: null,
  expectedPathLatLngs: null,
  actualPathLatLngs: null,
  linkedLocations: {},
  trackIcons: null,
  geoFenceFilterIds:null,
  plotGeoFencesStatus: false,
  carGpsHistoricalData: null,
  selectedVehicleAssetData: null,
  eventsDataByVehicle: {},
  eventSubTypesDataByVehicle:{},
  loaderForGetCarGpsHistoricalData: false
};


export const FleetReducer = createReducer(
  initialState,
  on(failure, (state, {error}) => {
    console.error(error);
    return {
      ...state,
      status: 'error',
      error: error
    };
  }),

  on(loadMarkerIconsSuccess, (state, {data, module}) => ({
    ...state,
    trackIcons: {
      [module]:data
    }
  })),

  on(setSelectedGroup, (state, {selectedGroupName}) => {
    return {
      ...state,
      selectedGroupName: selectedGroupName
    };
  }),

  on(updateVehicleGpsData, (state, {socketGpsData, module}) => {
    if (state && state?.allVehicles) {
      const updatedVehicles: VehicleObject[] = state.allVehicles[module]?.map(data => {
        const gpsDataCheck = data._id === socketGpsData?.assetId ? {...socketGpsData, latitude: socketGpsData?.location?.latitude, longitude: socketGpsData?.location?.longitude} : data.gpsData;
        if (gpsDataCheck) {
          let isOffline = true;
          if (gpsDataCheck?.generatedTimeStamp) {
            const diff = moment().diff(moment(gpsDataCheck?.generatedTimeStamp), 'minute');
            isOffline = diff >= CONSTANT.TIMEINTERVALS.OFFLINEINMIN;
          }
          return {...data, gpsData: gpsDataCheck, isOffline: isOffline};
        }

        return data;
      });
      let updatedSelectedVehicle: VehicleObject;

      if (state.selectedVehicle && state.selectedVehicle?._id === socketGpsData.assetId) {
        const diff = moment().diff(moment(socketGpsData?.generatedTimeStamp), 'minute');
        updatedSelectedVehicle = {
          ...state.selectedVehicle,
          gpsData: {...socketGpsData, latitude: socketGpsData?.location?.latitude, longitude: socketGpsData?.location?.longitude},
          isOffline: diff >= CONSTANT.TIMEINTERVALS.OFFLINEINMIN
        };
      } else if (state.selectedVehicle) {
        const index = updatedVehicles.findIndex((vehicle) => vehicle._id === state.selectedVehicle._id);
        updatedSelectedVehicle = {...state.selectedVehicle, isOffline: updatedVehicles[index]?.isOffline};
      } else {
        updatedSelectedVehicle = state.selectedVehicle;
      }

      let updatedSpeedData: GraphData = {...state.speedData};
      let updatedFuelData: GraphData = {...state.fuelData};
      let updatedSensorData: SensorData = cloneDeep(state.sensorData)
      if (state?.selectedVehicle && state?.selectedVehicle._id === socketGpsData.assetId) {
        if (state.speedData && socketGpsData?.speed) {
          updatedSpeedData.xAxis = [...state.speedData.xAxis, socketGpsData?.generatedTimeStamp];
          updatedSpeedData.yAxis = [...state.speedData.yAxis, socketGpsData?.speed];
        }
        if (state.fuelData && socketGpsData?.fuel) {
          updatedFuelData.xAxis = [...state.fuelData.xAxis, socketGpsData?.generatedTimeStamp];
          updatedFuelData.yAxis = [...state.fuelData.yAxis, socketGpsData?.fuel];
        }
        if (state?.sensorData && socketGpsData?.sensorData?.temperature) {
          updatedSensorData.temperature.xAxis = [...state.sensorData.temperature.xAxis, moment(socketGpsData?.generatedTimeStamp).format('MMMM Do YYYY, h:mm a')];
          updatedSensorData.temperature.yAxis = [...state.sensorData.temperature.yAxis, socketGpsData?.sensorData?.temperature];
        }
        if (state?.sensorData?.humidity && socketGpsData?.sensorData?.humidity) {
          updatedSensorData.humidity.xAxis = [...state.sensorData.humidity.xAxis, moment(socketGpsData?.generatedTimeStamp).format('MMMM Do YYYY, h:mm a')];
          updatedSensorData.humidity.yAxis = [...state.sensorData.humidity.yAxis, socketGpsData?.sensorData?.humidity];
        }
      } else {
        updatedSensorData = state.sensorData;
        updatedSpeedData = state.speedData;
        updatedFuelData = state.fuelData;
      }

      const av = {...state.allVehicles};
      av[module] = updatedVehicles;

      return {
        ...state,
        allVehicles: av,
        selectedVehicle: updatedSelectedVehicle,
        lastUpdatedVehicle: socketGpsData,
        speedData: updatedSpeedData,
        fuelData: updatedFuelData,
        sensorData: updatedSensorData
      };
    }

  }),

  on(loadVehiclesSuccess, (state, {all_vehicles, module}) => {
    const av = {...state.allVehicles};
    av[module] = all_vehicles;
    return {
      ...state,
      status: 'success',
      allVehicles: av,
    };
  }),

  on(loadSelectedTrackableVehiclesSuccess, (state, { selected_vehicles, module }) => {
    const av = { ...state.selectedTrackableVehicles };
    av[module] = selected_vehicles;
    return {
      ...state,
      status: 'success',
      selectedTrackableVehicles: av,
    };
  }),

  on(updateVehicleInfoSuccess, (state, {selectedVehicle, module}) => {
    let updatedVehicle: VehicleObject;
    const index = state?.allVehicles[module]?.findIndex((vehicle) => vehicle._id === selectedVehicle._id);
    if (index > -1) {
      updatedVehicle = {...selectedVehicle, isOffline: state.allVehicles[module]?.[index]?.isOffline};
    } else {
      updatedVehicle = {...selectedVehicle, isOffline: true};
    }

    return {
      ...state,
      status: 'success',
      selectedVehicle: updatedVehicle,
    };
  }),

  on(loadDistanceMovedSuccess, (state, {distanceMovedData}) => {
    const x = [];
    const y = [];
    for (let i = 0; i <= distanceMovedData.length; i++) {
      const data = distanceMovedData[i];
      if (data?.distanceKm && data.updated) {
        y.push(data.distanceKm);
        x.push(moment(data.updated).format('MMMM Do YYYY, h:mm a'));
      }
    }
    return {
      ...state,
      status: 'success',
      distanceMovedData: {
        name: 'Distance Travelled',
        xAxis: x,
        yAxis: y
      },
    };
  }),

  on(loadSpeedDataSuccess, (state, {speedData}) => {
    const x = [];
    const y = [];

    for (let i = 0; i <= speedData.length; i++) {
      const data = speedData[i];
      if (data?.speed && data?.updated) {
        y.push(data.speed);
        x.push(moment(data.updated).format('MMMM Do YYYY, h:mm a'));
      }
    }
    return {
      ...state,
      status: 'success',
      speedData: {
        name: 'Speed',
        xAxis: x,
        yAxis: y
      },
    };
  }),

  on(loadFuelDataSuccess, (state, {fuelData}) => {
    const x = [];
    const y = [];
  //  const allVehicle = [...state.allVehicles[moduleName]]
   // let selectedVeh = allVehicle.find(v => v._id === assetId);

    for (let i = 0; i <= fuelData.length; i++) {
      const data = fuelData[i];
      if (data?.fuel && data?.updated) {
        y.push(data.fuel);
        x.push(moment(data.updated).format('MMMM Do YYYY, h:mm a'));
      }
    }

    /*  if(selectedVeh) {
       selectedVeh = {...selectedVeh};
       if(y.length > 0 && y[y.length-1])
       selectedVeh['gpsData'] = {...selectedVeh['gpsData'], fuel:y[y.length-1]}
     } */

  //  const uAllVehicle = {...state.allVehicles};
  //  uAllVehicle[moduleName] = allVehicle;

    return {
      ...state,
      status: 'success',
    //  allVehicles: uAllVehicle,
      fuelData: {
        name: 'Fuel',
        xAxis: x,
        yAxis: y
      },
    };
  }),

  on(loadSensorDataSuccess, (state, {sensorData}) => {
    const tempX = [];
    const tempY = [];

    const humidityX = [];
    const humidityY = [];

    for (let i = 0; i <= sensorData.length; i++) {
      const data = sensorData[i];
      if (data?.updated) {
        const time = moment(data.updated).format('MMMM Do YYYY, h:mm a');
        if (data?.temperature) {
          tempY.push(data.temperature);
          tempX.push(time);
        }
        if (data?.humidity) {
          humidityY.push(data.humidity);
          humidityX.push(time);
        }
      }
    }
    return {
      ...state,
      status: 'success',
      sensorData: {
        temperature: {
          name: 'Temperature',
          xAxis: tempX,
          yAxis: tempY
        },
        humidity: {
          name: 'Humidity',
          xAxis: humidityX,
          yAxis: humidityY
        }
      },
    };
  }),

  on(loadEventsDataSuccess, (state, {eventsData}) => {
    return {
      ...state,
      status: 'success',
      eventsData: eventsData,
    };
  }),

  on(loadEventsDataCountSuccess, (state, {eventsDataCount}) => {
    return {
      ...state,
      status: 'success',
      eventsCount: eventsDataCount,
    };
  }),

  on(loadEventStatusDataSuccess, (state, {eventsStatusData}) => {
    return {
      ...state,
      status: 'success',
      eventsStatusData: eventsStatusData,
    };
  }),

  on(loadAssetsLinkedDataSuccess, (state, {assetsLinked}) => {
    return {
      ...state,
      status: 'success',
      selectedVehicle: {...state.selectedVehicle, assetsLinked: assetsLinked},
      selectedVehicleAssetData: { ...state.selectedVehicle, assetsLinked: assetsLinked }
    };
  }),

  on(loadAssignedOrdersGroupDataSuccess, (state, {data, module}) => {
    let updatedSelectedVehicle: VehicleObject = state.selectedVehicle;
    let updatedVehicles: VehicleObject[] = state?.allVehicles?.[module];
    if (state?.allVehicles) {
      updatedVehicles = state.allVehicles?.[module]?.map(v => {
        const vf = data.find(o => o.agentId === v._id);
        if (vf) {
          const oa = [...vf.deliveryOrderStatus, ...(vf?.cancelledDeliveries ?? [])];
          if (state?.selectedVehicle && state?.selectedVehicle?._id === vf._id) {
            updatedSelectedVehicle = {
              ...state.selectedVehicle,
              ordersAssigned: {deliveryOrderStatuses: oa, pickupLocation: vf.pickUpLocation}
            };
          }
          return {...v, ordersAssigned: {deliveryOrderStatuses: oa, pickupLocation: vf.pickUpLocation}};
        } else {
          return v;
        }
      });
    }
    const av = {...state.allVehicles};
    av[module] = updatedVehicles;
    return {
      ...state,
      status: 'success',
      allVehicles: av,
      selectedVehicle: updatedSelectedVehicle
    };
  }),

  /*on(loadAssignedOrdersDataSuccess, (state, {assignedOrders, agentId}) => {
    if (state && state?.allVehicles) {
      const updatedVehicles: VehicleObject[] = state.allVehicles.map(data => {
        if (data?._id === agentId) {
          return {...data, ordersAssigned: assignedOrders};
        }
        return data;
      });

      let updatedSelectedVehicle: VehicleObject;
      if (state?.selectedVehicle && state?.selectedVehicle?._id === agentId) {
        updatedSelectedVehicle = {...state.selectedVehicle, ordersAssigned: assignedOrders};
      } else {
        updatedSelectedVehicle = state.selectedVehicle;
      }

      return {
        ...state,
         status: 'success',
         allVehicles: updatedVehicles,
         selectedVehicle: updatedSelectedVehicle
      };
    }
  }),*/

  on(loadGeofencesSuccess, (state, {geofences}) => {
    return {
      ...state,
      status: 'success',
      geofences: geofences,
    };
  }),

  on(updateGeofencesSuccess, (state, {geofencesCount}) => {
    if (state?.geofences) {
      const updatedGeofences: GeoFenceObject[] = state.geofences.map(geofence => {
        const index = geofencesCount.findIndex((geofenceCount) => geofenceCount.geoFence.id === geofence._id);
        if (index > -1) {
          return {...geofence, count: geofencesCount[index].count};
        }
        return {...geofence, count: 0};
      });
      return {
        ...state,
        status: 'success',
        geofences: updatedGeofences.length > 0 ? updatedGeofences : state?.geofences,
      };
    }
  }),

  on(resetTrackState, (state) => {
    return {
      ...state,
      selectedVehicle: null,
      selectedGroupName: null,
      // allVehicles: null,
      lastUpdatedVehicle: null,
      distanceMovedData: null,
      speedData: {
        name: 'Speed',
        xAxis: [],
        yAxis: []
      },
      fuelData: {
        name: 'Fuel',
        xAxis: [],
        yAxis: []
      },
      sensorData: {
        temperature: {
          name: 'Temperature',
          xAxis: [],
          yAxis: []
        },
        humidity: {
          name: 'Humidity',
          xAxis: [],
          yAxis: []
        }
      },
      eventsCount: null,
      eventsData: null,
      geofences: null,
      eventsStatusData: null,
      status: 'pending',
      error: null,
      mapIconData: null,
      successMsg: null
    };
  }),

  on(resetSelectedVehicle, (state) => {
    return {
      ...state,
      selectedVehicle: null,
      distanceMovedData: null,
      speedData: {
        name: 'Speed',
        xAxis: [],
        yAxis: []
      },
      fuelData: {
        name: 'Fuel',
        xAxis: [],
        yAxis: []
      },
      sensorData: {
        temperature: {
          name: 'Temperature',
          xAxis: [],
          yAxis: []
        },
        humidity: {
          name: 'Humidity',
          xAxis: [],
          yAxis: []
        }
      },
      eventsCount: null,
      eventsData: null,
      eventsStatusData: null,
    };
  }),

  on(getMapIconsSettingsSuccess, (state, {data}) => {
    const mapIconObj: IMapIcons[] = modifyResponseForStore(data);
    return {
      ...state,
      mapIconData: mapIconObj
    };
  }),

  on(updateMapIconSettingsSuccess, (state, { moduleType}) => {
    return {
      ...state,
      successMsg: Object.create({
        msg: `Updated ${moduleType} map icons URLs Successfully`,
      }),
    };
  }),

  on(updateMapIconObjectField, (state, {updatedValue, index, feildToUpdate}) => {
    const updatedObject: IMapIcons[] = JSON.parse(JSON.stringify(state.mapIconData));
    updatedObject[index][feildToUpdate] = updatedValue;

    return {
      ...state,
      mapIconData: updatedObject
    };
  }),

  on(loadGeoAssetsByIdSuccess, (state, {geoAssets, module}) => {
    const dgeoAssets = [];
    geoAssets.forEach(geoAsset => {
      const id = geoAsset?.assets?.id;
      const allAssets = state.allVehicles?.[module];
      for (let i = 0; i < allAssets?.length; i++) {
        if (allAssets[i]?._id === id) {
          const obj = {
            _id: id,
            inTime: geoAsset?.assets?.inTime,
            name: allAssets[i]?.name
          };

          dgeoAssets.push(obj);
        }
      }
    });

    return {
      ...state,
      geoAssets: dgeoAssets,
    };
  }),

  on(getExpectedPathsForCarrierSuccess, (state, {data}) => {
    if (!data['success']) {
      return {...state};
    }
    return {
      ...state,
      expectedPathLatLngs: data['latLngs']
    };
  }),

  on(getActualPathsForCarrierSuccess, (state, {data}) => {
    if (!data['success']) {
      return {...state};
    }
    return {
      ...state,
      actualPathLatLngs: data['latLngs']
    };
  }),

  on(getActualPathsForCarrierWithoutApiCall, (state, {historicalGpsData}) => {
    const latLngs = [];
    historicalGpsData.forEach(d => {
      const coordinates = d?.coordinates;
      if(coordinates && coordinates?.[0] && coordinates?.[1])
      latLngs.push(coordinates);
    });
    return {
      ...state,
      actualPathLatLngs: latLngs
    };
  }),

  on(getExpectedPathsForCarrierWithoutApiCall, (state, {latLngs}) => {
    return {
      ...state,
      expectedPathLatLngs: latLngs
    };
  }),

  on(resetExpectedAndActualPath, (state) => {
    return {
      ...state,
      expectedPathLatLngs: null,
      actualPathLatLngs: null
    };
  }),

  on(getLinkedLocationsSuccess, (state, {data, cityId}) => {
    return {
      ...state,
      linkedLocations: {...state.linkedLocations, [cityId]: data}
    }
  }),

  on(updateGeoFenceById, (state, {geofence,geofenceId,consignmentId}) => {
    const geofenceCopy=state?.geofences?.map((fence) => {
      if (fence.assetId === geofenceId) {
        const coordinates= (geofence?.location?.coordinates?.[0] || []).map(c=>{
          return [c[1],c[0]]
        })
        return {...geofence,location:{...(geofence.location),coordinates:[...coordinates]}}
      }
      return fence
    });

    const filteredGeofences = 
    consignmentId ? 
    geofenceCopy.filter(geofence=>state.geoFenceFilterIds?.[consignmentId]?.includes(geofence.assetId)) :
    state.filteredGeofences
    return {
      ...state,
      geofences:geofenceCopy || [],
      filteredGeofences,
      plotGeoFencesStatus:true
    }
  }),
  on(setLocationIdsForGeoFenceFiltering, (state, {data}) => {
    const geoFenceFilterIds={}
    data.forEach(consignment=>{
      const consignmentId=consignment?._id
      geoFenceFilterIds[consignmentId]=[consignment?.pickUpLocation?._id]
      consignment.deliveryOrderStatus.forEach(location=>{
        geoFenceFilterIds[consignmentId].push(location?.deliveryCenterId)
      })
    })
    return {
      ...state,
      geoFenceFilterIds
    };
  }),

  on(filterGeoFencesForConsignment, (state, {consignmentId}) => {
    const geofences = state.geofences.filter(geofence=>state.geoFenceFilterIds?.[consignmentId]?.includes(geofence.assetId))
    return {
      ...state,
      filteredGeofences:geofences
    };
  }),
  on(changePlotGeoFencesStatus, (state) => {
    return {
      ...state,
      plotGeoFencesStatus:false
    };
  }),
  on(getCarGpsHistoricalData, (state) => {
    return {
      ...state,
      loaderForGetCarGpsHistoricalData: true
    }
  }),
  on(getCarGpsHistoricalDataSuccess, (state, { data }) => {
    return {
      ...state,
      carGpsHistoricalData: data,
      loaderForGetCarGpsHistoricalData: false
    };
  }),
  on(clearEventsDataForAllVehicles, (state,) => {
    return {
      ...state,
      eventsDataByVehicle: {},
    };
  }),

  on(getEventsForVehicleSuccess, (state, { vehicleId, data, page, subType }) => {
    const eventsDataByVehicle = {
      ...(state.eventsDataByVehicle || {}),
      [vehicleId]: {
        count: data.pagination.count,
        subType: subType || '',
        data: {
          ...(state.eventsDataByVehicle?.[vehicleId]?.data || {}),
          [page]: data.data
        }
      }
    }
    return {
      ...state,
      eventsDataByVehicle
    };
  }),

  on(clearEventsSubTypesDataForAllVehicles, (state,) => {
    return {
      ...state,
      eventSubTypesDataByVehicle: {},
    };
  }),

  on(getEventsSubTypesForVehicleSuccess, (state, { vehicleId, subTypes }) => {
    const eventSubTypesDataByVehicle = {
      ...(state.eventSubTypesDataByVehicle || {}),
      [vehicleId]: subTypes
    }
    return {
      ...state,
      eventSubTypesDataByVehicle
    };
  }),
  on(getCarGpsHistoricalDataFaliure, (state) => {
    return {
      ...state,
      loaderForGetCarGpsHistoricalData: false
    }
  })
);

function modifyResponseForStore(data: TrackerMapIconsSettings) {
  const mapIcons: IMapIcons[] = [
    {
      label: '',
      online: '',
      offline: '',
      active: ''
    },
  ];
  if (data !== null) {
    const status = ['online', 'offline', 'active'];
    mapIcons[0].label = 'Default';
    for (let i = 0; i < status.length; i++) {
      const status1 = status[i];
      mapIcons[0][status1] = data?.['defaultTrackerIcons']?.[status1];
    }

    if (data?.trackAssets?.length > 0) {
      for (let i = 0; i < data.trackAssets.length; i++) {
        const assetType = data.trackAssets[i];
        const tempObj = {
          label: '',
          online: '',
          offline: '',
          active: ''
        };
        tempObj['label'] = camelCaseToTitleCase(assetType?.['asset']);
        forEach(status, (status) => {
          tempObj[status] = assetType?.['icons']?.[status];
        });
        mapIcons.push(tempObj);

      }
    }
  }
  return mapIcons;
}

function camelCaseToTitleCase(text: string) {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}
